import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LoginScreen from './pages/login/loginScreen'
import CreateAccount from './pages/create-account/CreateAccount'
import VerifyEmailScreen from './pages/verify-email/VerifyEmailScreen'
import WelcomeScreen from './pages/welcome/welcomeScreen'
import DoctorProfileScreen from './pages/medicalProfile/doctorProfileScreen'
import { Lobby } from './pages/videoRoom'
import Logout from './pages/login/logout'
import { Alert, AlertColor, Snackbar } from '@mui/material'
import { useAlertStore } from './store/alertStore'
import ChangePassword from './pages/change-pass/changepass'
import PasswordRecovery from './pages/pass-recovery/PasswordRecovery'
import Availability from './pages/availability'
import AdminProfileDashboard from './pages/admin-profile/adminProfileDashboard'
import Admins from './pages/admins'
import AdminMedicalDetailsScreen from './pages/admin-profile/adminMedicalDetailsScreen'
import RapportsInitialScreen from './pages/rapports/rapportsInitialScreen'
import RapportsDetailsIndex from './pages/rapports/rapportsDetailsIndex'
import NotificationsIndexScreen from './pages/notifications'
import AppoinmentsScreen from './pages/appoinments/AppoinmentsScreen'
import Reports from './pages/reports'
import ReportsAdmin from './pages/reports-admin'
import MercadoPagoLandingScreen from './pages/mercado-pago-landing'
import { useNotificationsStore } from './store/notificationsStore'
import { getNotifications } from './services/notifications'
import { useEffect } from 'react'
import { authStore } from './store/auth'
import { useStore } from 'zustand'
import MedicalActivityScreen from './pages/reports-admin/medicalActivityScreen'
import TermsAndConditions from './pages/create-account/TermsAndConditions'
import TermsAndConditionsMobile from './pages/create-account/TermsAndConditionsMobile'
import PoliticasPrivacidad from './pages/create-account/PoliticasPrivacidad'
import TableFees from './pages/create-account/TableFees'
import DeleteUser from './pages/delete-user/DeleteUser'

const App = () => {
  const { isOpen, handleClose, message, severity, } = useAlertStore()
  const { setNotifications } = useNotificationsStore()
  const { user } = useStore(authStore)

  useEffect(() => {
    let interval: NodeJS.Timeout
    const getNotificationsFromServer = async () => {
      const response = await getNotifications()
      setNotifications(response.items, response.hasUnreadNotifications)
    }

    if (user?.id) {
      getNotificationsFromServer()
      // By default use 5 minutes
      const mins = process.env.REACT_APP_NOTIFICATION_INTERVAL_IN_MIN ? parseInt(process.env.REACT_APP_NOTIFICATION_INTERVAL_IN_MIN) : 5
      // Convert minutes to miliseconds
      const timeInterval = mins * 60 * 1000

      interval = setInterval(() => {
        getNotificationsFromServer()
      }, timeInterval)
    }

    return () => {
      clearInterval(interval)
    }

  }, [setNotifications, user])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<LoginScreen />}></Route>
          <Route path="/login" element={<LoginScreen />}></Route>
          <Route path="/create-account" element={<CreateAccount />}></Route>
          <Route path="/verify-email" element={<VerifyEmailScreen />}></Route>
          <Route path="/profile" element={<DoctorProfileScreen />}></Route>
          <Route path="/welcome" element={<WelcomeScreen />}></Route>
          <Route path="/video-room" element={<Lobby />}></Route>
          <Route path="/logout" element={<Logout />}></Route>
          <Route
            path="/recovery-password"
            element={<PasswordRecovery />}
          ></Route>
          <Route path="/change-password" element={<ChangePassword />}></Route>
          <Route path="/availability" element={<Availability />}></Route>
          <Route path='/admin/doctors' element={<AdminProfileDashboard />}></Route>
          <Route path="/admin/admins" element={<Admins />}></Route>
          <Route path='/admin/doctors/:id' element={<AdminMedicalDetailsScreen />}></Route>
          <Route path='/info' element={<RapportsInitialScreen />}></Route>
          <Route path='/info/:id' element={<RapportsDetailsIndex />}></Route>
          <Route path='/notifications' element={<NotificationsIndexScreen />}></Route>
          <Route path='/turnos' element={<AppoinmentsScreen />}></Route>
          <Route path='/reports' element={<Reports />}></Route>
          <Route path='/reports/:id' element={<MedicalActivityScreen />}></Route>
          <Route path='/admin/reports' element={<ReportsAdmin />}></Route>
          <Route path='/mercado-pago/callback' element={<MercadoPagoLandingScreen />}></Route>
          <Route path='/terms-and-conditions' element={<TermsAndConditions />}></Route>
          <Route path='/privacy-policies' element={<PoliticasPrivacidad />}></Route>
          <Route path='/patient/terms-and-conditions' element={<TermsAndConditionsMobile />}></Route>
          <Route path='/table-fees' element={<TableFees />}></Route>
          <Route path='/how-to-delete-user' element={<DeleteUser />}></Route>
        </Routes>
      </BrowserRouter>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={false}
          className="text-white"
          onClose={() => handleClose()}
          style={{
            backgroundColor: severity === 'success' ? '#C2D400' : '#F54135'
          }}
          severity={severity as AlertColor}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default App
