import { AxiosResponse } from 'axios'
import { http } from '../utils/http'

export const addProfilePicture = async (
  file: File
): Promise<AxiosResponse | any> => {
  const bodyFormData = new FormData()
  bodyFormData.append('file', file)
  try {
    const response = await http.post(
      '/api/user/profile-picture',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response
  } catch (error) {
    return error
  }
}

export const deleteProfilePicture = async (): Promise<AxiosResponse | any> => {
  try {
    const response = await http.delete('/api/user/profile-picture')

    return response
  } catch (error) {
    return error
  }
}

export const refreshDoctorData = async () => {
  try {
    const response = await http.get(
      '/api/doctor/me'
    )
    return response.data
  } catch (error) {
    return error
  }
}
export const deleteUser = async (): Promise<AxiosResponse | any> => {
  try {
    const response = await http.delete('/api/user')

    return response
  } catch (error) {
    return error
  }
}
