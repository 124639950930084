import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import RapportsHistoric from './rapportsHistoric'
import RapportsMedicalNotes from './rapportsMedicalNotes'
import { Button } from '@mui/material'
import { getAntecedent, getHistory, getNotes } from '../../services/rapports'
import { useRapportsStore } from '../../store/rapportsStore'
import MedicalRecords from './rapportsMedicalRecords'
import ArrowGoPreviousPage from '../../components/arrowGoPreviousPage'

const RapportsDetailsIndex = () => {
  const [tabClicked, setTabClicked] = useState<string>('medicalRecords')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const location = useLocation()
  const { state }: { state: any } = location
  const {
    notes,
    history,
    medicalRecords,
    setMedicalRecords,
    setNotes,
    setHistory,
  } = useRapportsStore()
  const [rapportDetails] = useState({
    ...state.rapportDetails,
    id: state.id,
  })
  const fullName =
    state.rapportDetails.firstName + ' ' + state.rapportDetails.lastName

  useEffect(() => {
    const apiCall = async () => {
      const notesResponse = await getNotes(state.id)
      setNotes(notesResponse)

      const historyResponse = await getHistory(state.id)
      setHistory(historyResponse)

      const antecedentResponse = await getAntecedent(state.id)
      setMedicalRecords(antecedentResponse)
    }

    apiCall()
  }, [setHistory, setNotes, setMedicalRecords, state])

  return (
    <Layout>
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row w-full h-full px-12">
          <div className="flex flex-col w-full justify-start pl-2 pr-2">
            <div className='mb-4'>
              <ArrowGoPreviousPage
                path={'/info'}
                title={'Volver a Información de cliente'}
              />
            </div>
            <div className="font-montserrat flex flex-row text-pitch-black font-bold text-2xl tracking-wider">
              {fullName}
            </div>
            <div className="font-montserrat mt-2 text-pitch-black font-normal text-sm tracking-wider">
              {state.rapportDetails.email}
            </div>
          </div>
          {tabClicked === 'medicalNotes' && (
            <Button
              color='secondary'
              variant='contained'
              className='w-[238px] h-[48px]'
              onClick={() => setOpenModal(true)}
            >
              Nueva nota
            </Button>
          )}
        </div>
        <div className='mx-12 p-6 bg-white mt-8'>
          <div className='flex'>
            <div className='flex flex-row bg-soft-grey justify-center items-center p-2 rounded-lg'>
              <div
                onClick={() => setTabClicked('medicalRecords')}
                className={`font-montserrat  text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${tabClicked === 'medicalRecords' && 'bg-aqua-soft'
                  }`}
              >
                Antecedentes médicos
              </div>
              <div
                onClick={() => setTabClicked('historical')}
                className={`font-montserrat text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${tabClicked === 'historical' && 'bg-aqua-soft'
                  }`}
              >
                Historial
              </div>
              <div
                onClick={() => setTabClicked('medicalNotes')}
                className={`font-montserrat  text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${tabClicked === 'medicalNotes' && 'bg-aqua-soft'
                  }`}
              >
                Notas médicas
              </div>
            </div>
          </div>
          {tabClicked === 'medicalRecords' && (
            <MedicalRecords
              rapportDetails={{ ...rapportDetails, medicalRecords }}
            />
          )}
          {tabClicked === 'historical' && (
            <RapportsHistoric
              rapportDetails={{ ...rapportDetails, notes, history }}
            />
          )}
          {tabClicked === 'medicalNotes' && (
            <RapportsMedicalNotes
              rapportDetails={{ ...rapportDetails, notes, history }}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default RapportsDetailsIndex
