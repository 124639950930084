import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import UserAvatar from '../../components/UserAvatar'
import { OptionsMessage } from '../medicalProfile/medicalProfileScreen'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { TextField } from '@mui/material'
import ArrowGoPreviousPage from '../../components/arrowGoPreviousPage'

const AdminMedicalDetailsScreen = () => {
  const [keyboardArrowIconSense, setKeyboardArrowIconSense] =
    useState<boolean>(true)
  const location = useLocation()
  /* const id = location.pathname.split('/')[3] */ //se comenta por ahora pero se usara mas adelante
  const { state }: { state: any } = location

  const insuranceArray = ['IOMA', 'OSDE', 'PAMI', 'SANCOR SALUD']
  const specialityArray = ['CARDIOLOGIA', 'NEUROLOGIA']

  const commonTextFieldLabelColor = {
    '& .MuiInputLabel-root': {
      color: '#D3D3D3',
    },
  }

  if (!state) {
    return (
      <Layout>
        <></>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col w-full justify-start pl-2 pr-2'>
          <div className='ml-12'>
            <ArrowGoPreviousPage
              path={'/admin/doctors'}
              title={'Volver a médicos'}
              state={{
                tabClicked: state.tabClicked,
              }}
            />
          </div>
          <div
            className={`font-montserrat pt-6 pb-12 pl-12 text-pitch-black font-bold text-2xl tracking-wider`}
          >
            Médicos
          </div>
          <div className='flex flex-col w-full h-full px-12'>
            <div className='flex flex-row w-full pt-6 bg-white'>
              <div className='w-1/5 h-full flex flex-col items-center justify-start p-6'>
                <div className='pb-6'>
                  <UserAvatar
                    imageUrl={state.drData.profilePictureUrl}
                    altText={state.drData.lastName}
                    size={170}
                  />
                </div>
                <div className='font-montserrat font-normal text-xl text-pitch-black p-1'>
                  {state.drData.firstName + ' ' + state.drData.lastName}
                </div>
                <div className='font-montserrat font-bold text-xs text-aqua-intense p-1'>
                  MATRÍCULA
                  <span className='font-montserrat font-bold text-xs uppercase text-aqua-intense p-1'>
                    {state.drData.licence}
                  </span>
                </div>
              </div>
              <div className='w-3/4 flex flex-col pl-6'>
                <div className='w-full font-montserrat pt-10 text-pitch-black font-bold text-lg tracking-wider pb-4 pl-2'>
                  Perfil entrevistador
                </div>
                <div className='flex flex-row w-full'>
                  <div className='w-1/2 flex flex-col border-b border-solid p-2 mr-6'>
                    <p className='font-montserrat font-normal text-lighter-grey text-xs pt-3 pb-3'>
                      Cobertura medica
                    </p>
                    <div className='flex flex-row flex-wrap'>
                      {insuranceArray.length !== 0 ? (
                        insuranceArray.map((ins, index) => (
                          <p
                            key={ins + index}
                            className='font-montserrat font-normal text-pitch-black text-xs bg-yellow-light p-2 rounded-xl mr-1'
                          >{`${ins}`}</p>
                        ))
                      ) : (
                        <OptionsMessage />
                      )}
                    </div>
                  </div>
                  <div className='w-1/2 flex flex-col border-b border-solid p-2'>
                    <p className='text-start font-montserrat font-normal text-lighter-grey text-xs pt-3 pb-3'>
                      Especialidad
                    </p>
                    <div className='flex flex-wrap'>
                      {specialityArray.length !== 0 ? (
                        specialityArray.map((ins, index) => (
                          <p
                            key={ins + index}
                            className='font-montserrat font-normal text-pitch-black text-xs bg-yellow-light p-2 rounded-xl mr-1'
                          >{`${ins}`}</p>
                        ))
                      ) : (
                        <OptionsMessage />
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col pt-4'>
                  <p className='font-montserrat font-normal text-lighter-grey text-xs pt-2 pl-1'>
                    Contanos acerca de vos
                  </p>
                  <p className='font-montserrat pt-2 pl-2 border-b border-solid pb-2'>
                    Borem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                  </p>
                </div>
                <div className='flex flex-col pt-6'>
                  <div className='w-full font-montserrat p-10 pl-2 text-pitch-black font-bold text-lg tracking-wider pb-8'>
                    Disponibilidad
                  </div>
                  <div className='flex flex-col'>
                    <div className='flex flex-row'>
                      <DoneIcon fontSize='small' className='p-1' />
                      <p className='font-montserrat pl-2 pb-8'>
                        Consultas por videollamada
                      </p>
                    </div>
                    <div className='flex flex-row'>
                      <DoneIcon fontSize='small' className='p-1' />
                      <p className='font-montserrat pl-2 pb-8'>
                        Consultas a domicilio
                      </p>
                    </div>
                    <div className='flex flex-row items-start'>
                      <DoneIcon fontSize='small' className='p-1' />
                      <div className='font-montserrat pl-2 pr-2 pb-8'>
                        Consultas presenciales
                      </div>
                      <button
                        onClick={() =>
                          setKeyboardArrowIconSense(!keyboardArrowIconSense)
                        }
                      >
                        {!keyboardArrowIconSense ? (
                          <KeyboardArrowDownIcon fontSize='small' />
                        ) : (
                          <KeyboardArrowUpIcon fontSize='small' />
                        )}
                      </button>
                    </div>
                    {!keyboardArrowIconSense && (
                      <div className='w-full flex flex-wrap'>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Calle'
                            variant='standard'
                            className='w-full'
                            value='Av Simon Bolivar'
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Altura'
                            variant='standard'
                            className='w-full'
                            value='1107'
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Piso/depto'
                            variant='standard'
                            className='w-full'
                            value='3 A'
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Provincia'
                            variant='standard'
                            className='w-full'
                            value='Buenos Aires'
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Ciudad'
                            variant='standard'
                            className='w-full'
                            value='Tandil'
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AdminMedicalDetailsScreen
