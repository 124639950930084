import { Button, IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAlertStore } from '../../../store/alertStore'
import { deleteAvailability } from '../../../services/availability'
import { useCalendarStore } from '../../../store/calendarStore'
import { SelectedModalProps } from '../../../utils/interfaces/props'

const DeleteAvailabilityModal = (props: SelectedModalProps) => {
  const { open, handleClose, selected, resetSelected } = props
  const { deleteAvailabilitySlots } = useCalendarStore() 
  const { setAlert } = useAlertStore()

  const submit = async () => {
    const availabilitySlotsIds: Array<number> = selected
      .filter(
        (value: string | number | undefined): value is string | number =>
          typeof value !== 'undefined'
      )
      .map((value: string | number): number => +value)
    const response = await deleteAvailability(availabilitySlotsIds)

    if (response.status !== 200) {
      setAlert({
        isOpen: true,
        message: 'Error al eliminar disponibilidad/es',
        severity: 'error'
      })
      return
    }
    if (response.status === 200) {
      setAlert({
        isOpen: true,
        message: `${
          availabilitySlotsIds.length > 1
            ? 'Se eliminaron éxitosamente las disponibilidades seleccionadas'
            : 'Se eliminó éxitosamente la dispoinibilidad seleccionada'
        }`,
        severity: 'success'
      })
      resetSelected()
      deleteAvailabilitySlots(availabilitySlotsIds)
      handleClose()
    }
  }

  return (
    <Modal open={open} className="flex items-center justify-center">
      <form>
        <div className="flex flex-col w-[600px] h-auto bg-white rounded">
          <div className="flex flex-row w-full items-start justify-end">
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="flex flex-col px-6 items-center">
            <div className="font-montserrat text-xl font-bold leading-24 tracking-wider">
              Eliminar disponibilidad
            </div>
            <div className="mt-4 font-montserrat text-[13px] font-normal leading-16 tracking-wider">
              {selected.length > 1
                ? '¿Estás seguro que querés eliminar las disponibilidades seleccionadas?'
                : '¿Estás seguro que querés eliminar esta disponibilidad? '}
            </div>

            <div className="flex flex-row w-full h-full justify-center space-x-6 mt-12 mb-6">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleClose()}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => submit()}
              >
                Eliminar disponibilidad
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default DeleteAvailabilityModal
