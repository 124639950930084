import { Button } from '@mui/material'
import DownloadIcon from '../../assets/download-icon.png'

const fileUrl = '../../assets/receta-electronica-instructivo.pdf'
const mapUrl =
  'https://www.google.com/maps/d/u/0/viewer?mid=1Wi9KpGOri8NmgxQixgB3F-cZXeKgP5I&ll=-37.24735934669487%2C-60.025109000000015&z=6'

const ElectronicPrescription = () => {
  return (
    <div className="flex flex-row h-full w-full pt-28 pl-10">
      <div className="flex flex-col w-4/5 h-full space-y-4">
        <div className="font-montserrat font-normal text-xl tracking-wider text-pitch-black">
          Receta electrónica Bonaerense
        </div>
        <p className="font-montserrat text-base leading-5 tracking-normal text-left">
          Si tenés matrícula vigente en la Provincia de Buenos Aires, podés
          registrar tu firma electrónica en los siguientes{' '}
          <a
            href={mapUrl}
            target="_blank"
            className="font-semibold"
            rel="noreferrer"
          >
            puntos de este mapa
          </a>
          , y comenzar a utilizar el sistema de receta electrónica bonaerense.
        </p>
        <p className="font-montserrat text-base leading-5 tracking-normal text-left mt-2">
          Para saber como realizar una receta electrónica, descargá el siguiente
          instructivo:
        </p>
        <a href={fileUrl} download>
          <Button className="w-[250px] text-red" variant="outlined">
            <img className="px-2" alt="download-icon" src={DownloadIcon}></img>
            Descargar instructivo
          </Button>
        </a>

        <p className="font-montserrat text-base leading-5 tracking-normal text-left mt-2">
          Si ya tenes usuario y contraseña, ingresá al {' '}
          <a
            href="https://shc.ms.gba.gov.ar/auth/login?returnUrl=%2Fhome"
            target="_blank"
            className="font-montserrat text-base leading-5 tracking-normal text-left font-semibold"
            rel="noreferrer"
          >
            siguiente link
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default ElectronicPrescription
