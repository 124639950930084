import { AxiosResponse } from 'axios'
import { http } from '../utils/http'

export const cancelAppointment = async (
  id: number,
  note: string
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.patch(`/api/web/appointment/${id}/cancel`, {
      message: note
    })
    return response
  } catch (error) {
    return error
  }
}

export const getAppointment = async (appointmentId: number) => {
  try {
    const response = await http.get(`/api/web/appointment/${appointmentId}`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getAppointments = async (startDate: Date, endDate: Date) => {
  try {
    const response = await http.get(`/api/web/appointment`, {
      params: {
        startDate: new Date(startDate.setHours(0, 0, 0, 0)),
        endDate: new Date(endDate.setHours(0, 0, 0, 0))
      }
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const getRoom = async (id: number) => {
  try {
    const response = await http.get(`/api/web/appointment/${id}/room`)
    return response.data
  } catch (error) {
    return error
  }
}

export const getTwilioToken = async (id: number): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post(`/api/web/appointment/${id}/room`)
    return response
  } catch (error) {
    return error
  }
}
