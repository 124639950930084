import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Layout } from '../../components/Layout'
import NotificationItem from './NotificationItem'
import {
  getNotifications,
  markNotificationsAsRead,
} from '../../services/notifications'
import { useNotificationsStore } from '../../store/notificationsStore'
import NoNotifications from './noNotifications'

const NotificationsIndexScreen = () => {
  const { notifications, setNotifications } = useNotificationsStore()

  useEffect(() => {
    const getNotificationsFromServer = async () => {
      const response = await getNotifications()
      setNotifications(response.items, response.hasUnreadNotifications)
    }

    getNotificationsFromServer()

    return () => {
      // When user leave the page, mark notifications as read
      const notificationsFilteredIfNotRead = notifications.filter(
        (n) => !n.read
      )
      const notificationsToPatch = notificationsFilteredIfNotRead.map(
        (n) => n.id
      )

      if (notificationsToPatch.length > 0) {
        markNotificationsAsRead(notificationsToPatch).then(() =>
          getNotificationsFromServer()
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNotifications])

  return (
    <Layout>
      <div
        className={`flex flex-col w-full h-full ${notifications.length === 0 && 'items-center'
          }`}
      >
        <div className='flex w-full items-center'>
          <div className='flex w-1/5 justify-start pl-2 pr-2'>
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight='bold'
            >
              Notificaciones
            </Typography>
          </div>
        </div>
        <div className='flex flex-col px-12'>
          {notifications.length > 0 ? (
            notifications.map((n) => {
              const createdAtTimestamp = new Date(n.createdAt).getTime()
              return (
                <NotificationItem
                  key={n.id}
                  id={n.id}
                  title={n.title}
                  description={n.description}
                  createdAt={createdAtTimestamp}
                  read={n.read}
                />
              )
            })
          ) : (
            <NoNotifications fontSize={20} imgClasses="w-auto h-auto" />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default NotificationsIndexScreen
