import { Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'
import { useState, useEffect } from 'react'
import { ChangePassModal } from './changePasswordModal'
import { updateDoctorProfile } from '../../services/doctor'
import { doctorStatuses } from '../../utils/constants'
import { useAlertStore } from '../../store/alertStore'
import EditableAvatar from '../../components/EditableAvatar'
import { AxiosResponse } from 'axios'
import CustomModal from '../../components/customModal'
import ModalCross from '../../assets/modal-cross.png'
import { deleteProfilePicture, deleteUser } from '../../services/user'
import { useNavigate } from 'react-router-dom'
import { userState } from '../../utils/interfaces/stores'

const ProfileForm = () => {
  const { user, setUser, setProfilePicture } = useStore(authStore)
  const navigate = useNavigate()
  const { setAlert } = useAlertStore()
  const {
    email,
    role,
    firstName,
    lastName,
    phone,
    doctorStatus,
    profilePictureUrl
  } = user as userState
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalDeletePicIsOpen, setDeletePicIsOpen] = useState<boolean>(false)
  const [modalDeleteUserIsOpen, setDeleteUserIsOpen] = useState<boolean>(false)
  const { APPROVAL_PENDING } = doctorStatuses

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z áéíóúñÁÉÍÓÚÑ]{1,25}$/,
        'El nombre debe tener sólo letras y entre 1 a 25 caracteres'
      )
      .required('Es obligatorio ingresar el nombre'),
    lastName: Yup.string()
      .matches(
        /^[a-zA-Z áéíóúñÁÉÍÓÚÑ]{1,25}$/,
        'El apellido debe tener sólo letras y entre 1 a 25 caracteres'
      )
      .required('Es obligatorio ingresar el apellido'),
    phone:
      role === 'DOCTOR'
        ? Yup.string()
          .matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/,
            'El número de teléfono no respeta el formato o la extensión necesaria'
          )
          .required('Es obligatorio ingresar el número de teléfono')
        : Yup.string()
  })

  const initialValues = {
    firstName,
    lastName,
    phone
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (role === 'DOCTOR') {
        const response: AxiosResponse | unknown = await updateDoctorProfile(
          values
        )
        const { status, data } = response as AxiosResponse

        if (status === 200) {
          setAlert({
            isOpen: true,
            message: 'Datos actualizados',
            severity: 'success'
          })

          const { firstName, lastName, phone } = data
          setUser({
            ...user,
            firstName,
            lastName,
            phone
          } as userState)
        }
      } else {
        setAlert({
          isOpen: true,
          message:
            'Error en la actualización, por favor verifique los datos ingresados',
          severity: 'error'
        })
      }
    }
  })

  const confirmDeleteProfilePicture = async () => {
    const response = await deleteProfilePicture()

    if (response.status === 200) {
      setProfilePicture('')
    }
    setDeletePicIsOpen(false)
  }

  const confirmDeleteUser = async () => {
    const response = await deleteUser()
    console.log(response)

    if (response.status === 200) {
      navigate('/logout', { replace: true })
    }
  }

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <form onSubmit={handleSubmit}>
      <div className="font-montserrat tracking-wider font-normal text-xl text-pitch-black pb-8">
        Datos personales
      </div>

      {role === 'DOCTOR' && (
        <div className="flex flex-row">
          <div className="relative pr-4 items-center flex flex-col">
            <EditableAvatar
              imageUrl={profilePictureUrl}
              altText={`${firstName} ${lastName}`}
              size={120}
            />
            {profilePictureUrl && profilePictureUrl.length > 0 && (
              <div className="mt-3">
                <Typography
                  color="error"
                  fontWeight="bold"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setDeletePicIsOpen(true)}
                >
                  Eliminar foto
                </Typography>
              </div>
            )}
          </div>
          <div className="flex flex-col p-2.5 h-full justify-center">
            <div className="font-montserrat font-normal text-base text-pitch-black p-1">
              {email}
            </div>
            <div className="font-montserrat font-bold text-xs uppercase text-aqua-intense p-1">
              {role === 'DOCTOR' ? 'Entrevistador' : role}
            </div>
          </div>
        </div>
      )}
      <div className="w-full pt-10">
        <div
          className={`flex ${role === 'DOCTOR' ? 'flex-wrap' : 'flex-row'
            } w-full pb-12 justify-between`}
        >
          <div className={`w-96 pb-4 ${role !== 'DOCTOR' && 'pr-4'}`}>
            <TextField
              label="Nombre"
              id="firstName"
              name="firstName"
              type="text"
              variant="outlined"
              className="w-full"
              autoComplete="off"
              inputProps={{ readOnly: doctorStatus === APPROVAL_PENDING }}
              value={values.firstName}
              error={!!errors.firstName && touched.firstName !== undefined}
              helperText={
                !!errors.firstName &&
                touched.firstName !== undefined &&
                errors.firstName
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={`w-96 pb-4 ${role !== 'DOCTOR' && 'pl-4'}`}>
            <TextField
              label="Apellido"
              id="lastName"
              name="lastName"
              type="text"
              variant="outlined"
              className="w-full"
              inputProps={{ readOnly: doctorStatus === APPROVAL_PENDING }}
              value={values.lastName}
              error={!!errors.lastName && touched.lastName}
              helperText={
                !!errors.lastName &&
                touched.lastName !== undefined &&
                errors.lastName
              }
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {role === 'DOCTOR' && (
            <div className="w-96">
              <TextField
                label="Teléfono"
                id="phone"
                name="phone"
                type="text"
                variant="outlined"
                autoComplete="off"
                inputProps={{ readOnly: doctorStatus === APPROVAL_PENDING }}
                className="w-full"
                value={values.phone}
                error={!!errors.phone && touched.phone}
                helperText={
                  !!errors.phone && touched.phone !== undefined && errors.phone
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col h-1/3 w-full justify-between">
        <div className="flex flex-col h-full pb-24">
          <div className="flex w-full justify-start">
            <Button
              disabled={doctorStatus === APPROVAL_PENDING}
              onClick={() => setModalOpen(true)}
            >
              <p className="font-montserrat underline bold tracking-wider text-pitch-black">
                Cambiar contraseña
              </p>
            </Button>
            <ChangePassModal
              open={modalOpen}
              handleClose={() => setModalOpen(false)}
            />
          </div>
        </div>
        <div className="flex w-full flex-row justify-between pb-6 bg-silver text-center">
          <Button
            disabled={doctorStatus === APPROVAL_PENDING}
            variant="text"
            color="error"
            onClick={() => setDeleteUserIsOpen(true)}
          >
            Eliminar cuenta
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!isValid || doctorStatus === APPROVAL_PENDING}
          >
            Guardar Cambios
          </Button>
        </div>
        <CustomModal
          openModal={modalDeletePicIsOpen}
          handleClose={() => setDeletePicIsOpen(false)}
          imgSrc={ModalCross}
          title="¿Estás seguro de eliminar tu foto de perfil?"
          description="Una vez eliminada no vas a poder recuperarla"
          onSubmit={() => confirmDeleteProfilePicture()}
          submitLabel="Aceptar"
          showCancelButton
          child={null}
          isSubmitDisable={null}
        />
        <CustomModal
          openModal={modalDeleteUserIsOpen}
          handleClose={() => setDeleteUserIsOpen(false)}
          imgSrc={ModalCross}
          title="¿Estás seguro de eliminar su cuenta?"
          description="Una vez eliminada no vas a poder recuperarla"
          onSubmit={() => confirmDeleteUser()}
          submitLabel="Aceptar"
          showCancelButton
          child={null}
          isSubmitDisable={null}
        />
      </div>
    </form>
  )
}

export default ProfileForm
