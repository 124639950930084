import { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import PersonalDataScreen from './personalDataScreen'
import MedicalProfileScreen from './medicalProfileScreen'
import AvailabilityScreen from './availabilityScreen'
import PaymentsScreen from './paymentsScreen'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'
import AdminInternalLayout from '../admin-profile/adminProfileInternalLayout'
import { doctorStatuses } from '../../utils/constants'
import { refreshDoctorData } from '../../services/user'
import { userState } from '../../utils/interfaces/stores'
import { useLocation } from 'react-router-dom'
import ElectronicPrescription from './electronicPrescription'

const DoctorProfileScreen = () => {
  const location = useLocation()
  const { state }: { state: any } = location
  const [pageCliked, setPageClicked] = useState<string>(
    state?.goToPayments ? 'paymentsScreen' : 'personalData'
  )
  const { user, setUser } = useStore(authStore)

  const { role, doctorStatus } = user as userState
  const { APPROVAL_PENDING } = doctorStatuses

  useEffect(() => {
    const refreshData = async () => {
      const response = await refreshDoctorData()
      const { hasMercadoPago, mercadoPagoEmail, mercadoPagoUserName } = response
      setUser({
        ...user,
        hasMercadoPago,
        mercadoPagoEmail,
        mercadoPagoUserName
        //add any other fields that should be refreshed
      } as userState)
    }
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pageClickedHandler = (str: string) => {
    setPageClicked(str)
  }

  let componentToRender

  switch (pageCliked) {
    case 'personalData':
      componentToRender = <PersonalDataScreen />
      break
    case 'medicalProfile':
      componentToRender = <MedicalProfileScreen />
      break
    case 'availabilityScreen':
      componentToRender = <AvailabilityScreen />
      break
    case 'paymentsScreen':
      componentToRender = <PaymentsScreen />
      break
    case 'electronicPrescription':
      componentToRender = <ElectronicPrescription />
      break
    default:
      componentToRender = <PersonalDataScreen />
      break
  }

  const SectionButton = (props: { page: string; title: string }) => {
    const { page, title } = props
    return (
      <div
        onClick={() => pageClickedHandler(page)}
        className={`font-montserrat pl-12 text-sm font-bold tracking-wider p-4 cursor-pointer ${pageCliked === page ? 'text-aqua-intense' : 'text-light-grey'
          }`}
      >
        {title}
      </div>
    )
  }

  if (role !== 'DOCTOR') componentToRender = <PersonalDataScreen />

  const sectionButtons = [{ page: 'personalData', title: 'Datos personales' }]

  if (!(doctorStatus === APPROVAL_PENDING)) {
    sectionButtons.push(
      { page: 'medicalProfile', title: 'Perfil entrevistador' },
      { page: 'availabilityScreen', title: 'Disponibilidad' },
      { page: 'paymentsScreen', title: 'Pagos' },
      // { page: 'electronicPrescription', title: 'Receta electrónica' }
    )
  }

  return (
    <Layout>
      <div className="flex flex-row w-full h-full">
        <div className="flex flex-col w-1/3 md:w-1/4 items-start justify-start pl-2 pr-2">
          <div
            className={`font-montserrat p-10 pl-12 text-pitch-black font-bold text-2xl tracking-wider`}
          >
            Perfil
          </div>

          {role === 'DOCTOR' && (
            <div className="border-r-2 w-full h-full flex flex-col">
              {sectionButtons.map(
                (section: { page: string; title: string }) => (
                  <SectionButton
                    key={section.page}
                    page={section.page}
                    title={section.title}
                  />
                )
              )}
            </div>
          )}
          {role !== 'DOCTOR' && <AdminInternalLayout />}
        </div>
        <div className="flex w-2/3 md:w-3/4">{componentToRender}</div>
      </div>
    </Layout>
  )
}

export default DoctorProfileScreen
