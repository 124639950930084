import { Typography } from '@mui/material'
import { Layout } from '../../components/Layout'
import CustomSearchBox from '../../components/CustomSearchBox'
import { useEffect, useState } from 'react'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'
import UserAvatar from '../../components/UserAvatar'
import EmptyAvatar from '../../assets/avatar_circular.png'
import NothingToShow from '../../components/nothingToShow'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import { RapportProps, RapportResponse } from '../../utils/constants'
import CustomDataGrid from '../../components/CustomDataGrid'
import { getRapports } from '../../services/rapports'
import { useRapportsStore } from '../../store/rapportsStore'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Reports = () => {
  const [searchText, setSearchText] = useState<string>('')
  const { rapports, setRapports } = useRapportsStore()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const apiCall = async () => {
      const response = await getRapports()

      setRapports(
        response.map((rapport: RapportResponse) => {
          const { patient } = rapport
          return {
            id: patient.id,
            lastName: patient.lastName,
            firstName: patient.firstName,
            email: patient.email,
            profilePictureUrl: patient.profilePictureUrl,
          }
        })
      )
    }
    apiCall()
  }, [setRapports])

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Cliente',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className='pl-4' fontWeight='bold'>
            Cliente
          </Typography>
        )
      },
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row items-center pl-4'>
            <UserAvatar
              imageUrl={
                cellValues.row.profilePictureUrl !== undefined
                  ? cellValues.row.profilePictureUrl
                  : EmptyAvatar
              }
              size={44}
              altText={'IMG'}
            />
            <Typography className='pl-8'>
              {cellValues.row.firstName} {cellValues.row.lastName}
            </Typography>
          </div>
        )
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className='pl-4' fontWeight='bold'>
            Email
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row items-center'>
            <Typography className='pl-4'>{cellValues.row.email}</Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'empty',
      headerName: '',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className='flex w-full text-end justify-end pr-6' onClick={() => navigate(`/reports/${cellValues.row.id}`, {
            replace: true, state: {
              medicalState: cellValues.row,
              pathToGoBack: location.pathname,
            }
          })}>
            <Typography
              className='tracking-wider text-center text-sm text-aqua-intense underline cursor-pointer'
              fontWeight='bold'
            >
              Ver actividad
            </Typography>
          </div>
        )
      },
      flex: 0.25,
      sortable: false,
    },
  ]

  const filterRow = (row: RapportProps) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase())

  return (
    <Layout>
      <div className='flex flex-col w-full h-full pb-8'>
        <div className='flex w-full items-center'>
          <div className='flex w-1/5 justify-start pl-2 pr-2'>
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight='bold'
            >
              Reportes
            </Typography>
          </div>
          <div className='flex w-4/5 justify-end pl-2 pr-2'>
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
          </div>
        </div>
        <div className='bg-white mx-14'>
          <div
            className={`flex mr-4 ${rapports.length !== 0 ? 'h-[495px]' : 'pb-12'
              }`}
          >
            {(searchText.length && rapports.filter(filterRow).length === 0) ||
              rapports.length === 0 ? (
              <NothingToShow
                nothingImg={nothingToShowImg}
                altText={'sin data de cliente'}
                nothingText={'No tenés nada que ver hasta el momento...'}
              />
            ) : (
              <CustomDataGrid
                rows={searchText.length ? rapports.filter(filterRow) : rapports}
                rowHeight={64}
                columns={columns}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Reports
