import { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
  Checkbox,
  FormControl,
  FormHelperText
} from '@mui/material'
import MedappLogo from '../../assets/logo-completo.png'
import { Link, useNavigate } from 'react-router-dom'
import { createDoctorAccount } from '../../services/auth'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAlertStore } from '../../store/alertStore'
import { userState } from '../../utils/interfaces/stores'

const CreateAccount = () => {
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true)
  const [confirmPasswordIsHidden, setConfirmPasswordIsHidden] =
    useState<boolean>(true)
  const navigate = useNavigate()
  const { setAlert } = useAlertStore()

  const { setUser, setVerifyToken } = useStore(authStore)

  const initialValues = {
    name: '',
    lastname: '',
    phone: '',
    license: '',
    email: '',
    password: '',
    repeatpassword: '',
    termsAndConditions: false
  }

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(
        /^[a-zA-Z áéíóúñÁÉÍÓÚÑ]{1,25}$/,
        'El nombre debe tener solo letras y entre 1 a 25 caracteres'
      )
      .required('Es obligatorio ingresar el nombre'),
    lastname: Yup.string()
      .matches(
        /^[a-zA-Z áéíóúñÁÉÍÓÚÑ]{1,25}$/,
        'El apellido debe tener solo letras y entre 1 a 25 caracteres'
      )
      .required('Es obligatorio ingresar el apellido'),
    phone: Yup.string()
      .matches(
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/,
        'El numero de teléfono no respeta el formato o la extensión necesaria'
      )
      .required('Es obligatorio ingresar el número de teléfono'),
    license: Yup.string()
      .matches(/^[0-9]+$/, 'Debe ingresar únicamente dígitos')
      .max(20, 'La licencia es demasiado larga')
      .required('Requerido'),
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, 'El email no tiene un formato válido')
      .max(60, 'El email es demasiada largo')
      .required('Requerido'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&Ññ]{8,16}$/,
        'La contraseña no es válida: debe contener una mayúscula, una minúscula, un dígito, con un mínimo de 8 a un máximo de 16 carácteres. No se permiten sino sólo algunos caracteres especiales'
      )
      .required('Requerido'),
    repeatpassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir')
      .required('Requerido'),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      'Debe aceptar los términos y condiciones'
    )
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        name: firstName,
        lastname: lastName,
        phone,
        license: licence,
        email,
        password
      } = values
      parseInt(licence)
      const response: any = await createDoctorAccount({
        firstName,
        lastName,
        phone,
        licence: parseInt(licence),
        email,
        password
      })

      if (response.status !== 201) {
        setAlert({
          isOpen: true,
          message:
            'Error en creación de la cuenta, por favor verifique los datos ingresados',
          severity: 'error'
        })
        return
      }

      if (response.status === 201) {
        const { firstName, lastName, userId, email, type, phone } =
          response.data.doctor
        setUser({
          firstName,
          lastName,
          email,
          phone,
          id: userId,
          role: type
        } as userState)

        setVerifyToken(response.data.verificationToken)
        navigate('/verify-email', { replace: true })

        setAlert({
          isOpen: true,
          message: 'Usuario creado con éxito...',
          severity: 'success'
        })
      }
    }
  })

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <div className="flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4">
      <div className="flex flex-col  items-center bg-white md:px-12 px-4 md:py-6 pb-4 mx-4 md:w-2/5 rounded-xl md:max-h-[80vh] max-h-[90vh] overflow-y-scroll">
        <div className='flex w-full justify-center'>
          <img
            src={MedappLogo}
            alt='intermedia login logo'
            className='mb-8'
            width={160}
            height={120}
          ></img>
        </div>
        <div className="flex justify-start w-full mb-4">
          <Typography fontSize={18} fontWeight="bold">
            Crear cuenta
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full items-center"
        >
          <div className="flex w-full justify-between mb-6">
            <div className="w-full mr-1">
              <TextField
                label="Nombre"
                variant="outlined"
                id="name"
                size="small"
                className="w-full white-background"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                error={!!errors.name && touched.name}
                helperText={
                  !!errors.name && touched.name !== undefined && errors.name
                }
              />
            </div>
            <div className="w-full ml-1">
              <TextField
                label="Apellido"
                variant="outlined"
                id="lastname"
                size="small"
                className="w-full white-background"
                value={values.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                error={!!errors.lastname && touched.lastname}
                helperText={
                  !!errors.lastname &&
                  touched.lastname !== undefined &&
                  errors.lastname
                }
              />
            </div>
          </div>
          <div className="flex w-full justify-between mb-6">
            <div className="w-full mr-1">
              <TextField
                label="Teléfono"
                variant="outlined"
                id="phone"
                size="small"
                className="w-full white-background"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                error={!!errors.phone && touched.phone}
                helperText={
                  !!errors.phone && touched.phone !== undefined && errors.phone
                }
              />
            </div>
            <div className="w-full ml-1">
              <TextField
                label="Matrícula"
                variant="outlined"
                id="license"
                size="small"
                type="string"
                className="w-full white-background"
                value={values.license}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                error={!!errors.license && touched.license}
                helperText={
                  !!errors.license &&
                  touched.license !== undefined &&
                  errors.license
                }
              />
            </div>
          </div>
          <div className="w-full">
            <TextField
              label="Email"
              variant="outlined"
              id="email"
              size="small"
              className="w-full white-background"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              error={!!errors.email && touched.email}
              helperText={
                !!errors.email && touched.email !== undefined && errors.email
              }
            />
          </div>
          <div className="w-full mt-6">
            <TextField
              label="Contraseña"
              variant="outlined"
              id="password"
              size="small"
              className="w-full white-background"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type={passwordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() => setPasswordIsHidden((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() => setPasswordIsHidden((prev) => !prev)}
                      />
                    )}
                  </InputAdornment>
                )
              }}
              onKeyPress={(e: any) =>
                e.charCode === 13 && isValid && handleSubmit()
              }
              autoComplete="off"
              error={!!errors.password && touched.password}
              helperText={
                !!errors.password &&
                touched.password !== undefined &&
                errors.password
              }
            />
          </div>
          <div className="w-full mt-6">
            <TextField
              label="Repetir contraseña"
              variant="outlined"
              id="repeatpassword"
              size="small"
              className="w-full white-background"
              value={values.repeatpassword}
              onChange={handleChange}
              onBlur={handleBlur}
              type={confirmPasswordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {confirmPasswordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setConfirmPasswordIsHidden((prev) => !prev)
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setConfirmPasswordIsHidden((prev) => !prev)
                        }
                      />
                    )}
                  </InputAdornment>
                )
              }}
              onKeyPress={(e: any) =>
                e.charCode === 13 && isValid && handleSubmit()
              }
              autoComplete="off"
              error={!!errors.repeatpassword && touched.repeatpassword}
              helperText={
                !!errors.repeatpassword &&
                touched.repeatpassword !== undefined &&
                errors.repeatpassword
              }
            />
          </div>
          <div className="flex flex-col w-full pt-4">
            <label className="flex flex-row font-montserrat not-italic font-normal text-sm text-pitch-black leading-4 cursor-pointer items-center">
              <div className="pr-2">
                <FormControl
                  sx={{
                    '& .MuiButtonBase-root': {
                      padding: '0px !important'
                    }
                  }}
                  error={
                    !!errors.termsAndConditions && touched.termsAndConditions
                  }
                >
                  <Checkbox
                    color="secondary"
                    id="termsAndConditions"
                    className="cursor-pointer"
                    value={values.termsAndConditions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </div>
              <p>
                Acepto los{' '}
                <Link
                  className="font-bold"
                  target={'_blank'}
                  to="/terms-and-conditions"
                >
                  términos y condiciones
                </Link>{' '}
                del servicio
              </p>
            </label>
            {!!errors.termsAndConditions &&
              touched.termsAndConditions !== undefined && (
                <FormHelperText
                  sx={{
                    color: 'red',
                    paddingLeft: '12px'
                  }}
                >
                  {errors.termsAndConditions}
                </FormHelperText>
              )}
          </div>
          <div className="md:mb-0 mb-4 mt-12 w-full">
            <Button
              color="secondary"
              type="submit"
              variant="contained"
              className="w-full"
              disabled={!isValid}
            >
              Crear cuenta
            </Button>
          </div>
          <div className="md:mb-0 mt-4 w-full">
            <Button
              color="secondary"
              variant="outlined"
              className="w-full"
              onClick={() => navigate('/login', { replace: true })}
            >
              Ya tengo cuenta
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateAccount
