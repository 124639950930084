import { AxiosResponse } from 'axios'
import { EditAvailabilityDto, NewAvailabilityDto } from '../utils/constants'
import { http } from '../utils/http'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const addNewAvailability = async (
  data: NewAvailabilityDto
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post('/api/doctor/availability-slot', {
      ...data
    })

    return response
  } catch (error) {
    return error
  }
}

export const editAvailability = async (
  data: EditAvailabilityDto
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.patch('/api/doctor/availability-slot', {
      timezone: timeZone,
      ...data
    })

    return response
  } catch (error) {
    return error
  }
}

export const deleteAvailability = async (
  availabilitySlotsIds: Array<number>
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.delete('/api/doctor/availability-slot', {
      data: { availabilitySlotsIds }
    })

    return response
  } catch (error) {
    return error
  }
}

export const getWeeklyData = async (startDate: Date, endDate: Date) => {
  try {
    const response = await http.get(
      `/api/doctor/availability-slot/web/weekly?startDate=${startDate}&endDate=${endDate}&timezone=${timeZone}`
    )
    return response.data
  } catch (error) {
    return error
  }
}

export const getMonthlyData = async (startDate: Date, endDate: Date) => {
  try {
    const response = await http.get(
      `/api/doctor/availability-slot/web/daily?startDate=${startDate}&endDate=${endDate}&timezone=${timeZone}`
    )
    return response.data
  } catch (error) {
    return error
  }
}
