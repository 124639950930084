import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, IconButton, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/system'
import VideocamIcon from '@mui/icons-material/Videocam'
import HomeIcon from '@mui/icons-material/Home'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useEffect } from 'react'
import { CustomSelect } from '../../../components/CustomSelect'
import { CustomDatePicker } from '../../../components/CustomDatePicker'
import { currentId, nextId, parseData } from '../utils'
import { hours, daysOfTheWeek, validity } from '../constants'
import SelectBox from './SelectBox'
import { addNewAvailability } from '../../../services/availability'
import { useAlertStore } from '../../../store/alertStore'
import { useCalendarStore } from '../../../store/calendarStore'
import { useDoctorStore } from '../../../store/doctor'
import { AddAvailabilityModalInitValueTypes } from '../../../utils/constants'
import { DefaultModalProps } from '../../../utils/interfaces/props'

const AddAvailabilityModal = (props: DefaultModalProps) => {
  const { open, handleClose } = props
  const { setAlert } = useAlertStore()
  const { updateWeeklyData } = useCalendarStore()
  const { availability } = useDoctorStore()

  const initialValues: AddAvailabilityModalInitValueTypes = {
    remote: availability.videocallAppointments,
    onSite: !availability.videocallAppointments ? true : false,
    date: new Date(),
    startHour: currentId(),
    endHour: nextId(currentId()),
    selectedDays: [],
    validity: ''
  }

  const validationSchema = Yup.object({
    remote: Yup.boolean(),
    onSite: Yup.boolean(),
    date: Yup.date().required('Este campo es requerido'),
    startHour: Yup.string().required('Este campo es requerido'),
    endHour: Yup.string().required('Este campo es requerido'),
    selectedDays: Yup.array(),
    validity: Yup.string().required('Este campo es requerido')
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isValid,
    validateForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const parsedData = await parseData(values)
      const response = await addNewAvailability(parsedData)
      if (response.status !== 201) {
        setAlert({
          isOpen: true,
          message: 'Error al agregar una nueva disponibilidad',
          severity: 'error'
        })
        return
      }

      if (response.status === 201) {
        setAlert({
          isOpen: true,
          message: 'Se agregó éxitosamente la nueva disponibilidad',
          severity: 'success'
        })
        updateWeeklyData(response.data)
        resetForm()
        handleClose()
      }
    }
  })

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const handleDatePicker = (date: Date) => {
    setFieldValue('date', date)
  }

  const handleSelectDay = (id: string) => {
    if (values.selectedDays.includes(id)) {
      setFieldValue(
        'selectedDays',
        values.selectedDays.filter((d) => d !== id)
      )
    } else {
      setFieldValue('selectedDays', [...values.selectedDays, id])
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="flex items-center justify-center"
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-[600px] h-[530px] bg-white rounded">
          <div className="flex flex-row w-full items-start justify-end">
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="flex flex-col px-6">
            <div className="font-montserrat text-xl font-bold leading-24 tracking-wider">
              Agregar disponibilidad
            </div>
            <div className="mt-4 font-montserrat text-[13px] font-normal leading-16 tracking-wider">
              Seleccioná que tipo de atención vas a brindar. Podés seleccionar
              más de una.
            </div>
            <div className=" mt-4 mb-2">
              <Box className="flex flex-row w-[300px] rounded-lg justify-between space-x-3">
                <SelectBox
                  selected={values.remote}
                  icon={<VideocamIcon />}
                  title={'Videollamada'}
                  id="remote"
                  onClick={() => setFieldValue('remote', !values.remote)}
                  disabled={!availability.videocallAppointments}
                />
                <SelectBox
                  selected={values.onSite}
                  icon={<HomeIcon />}
                  title={'Presencial'}
                  id="onSite"
                  onClick={() => setFieldValue('onSite', !values.onSite)}
                  disabled={!availability.presentialAppointments}
                />
              </Box>
              {!values.onSite && !values.remote && (
                <div className="mt-2 font-montserrat text-red-600 text-[12px] font-normal leading-16 tracking-wider">
                  Seleccioná al menos un tipo de atención.
                </div>
              )}
            </div>
            <Box className="w-full flex flex-row space-x-3 items-center mt-3">
              <AccessTimeIcon />

              <CustomDatePicker
                value={values.date}
                onChange={handleDatePicker}
                className="w-1/3"
                disablePast
              />
              <CustomSelect
                className="white-background"
                id="startHour"
                label="Desde"
                options={hours.filter(
                  (d) => parseInt(d.id) !== parseInt(hours[hours.length - 1].id)
                )}
                onChange={(e) => {
                  handleChange(e)
                  setFieldValue('endHour', nextId(e.target.value))
                }}
                onBlur={handleBlur}
                value={values.startHour}
                error={undefined}
                helperText={undefined}
                name="startHour"
                placeholder="startHour"
                size="small"
              />
              <CustomSelect
                className="white-background"
                id="endHour"
                name="endHour"
                placeholder="endHour"
                label="Hasta"
                options={hours.filter(
                  (d) => parseInt(d.id) > parseInt(values.startHour)
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.endHour}
                disabled={values.startHour === hours[hours.length - 2].id}
                error={undefined}
                helperText={undefined}
                size="small"
              />
            </Box>

            <div className="mt-8 mb-8 space-y-3">
              <p className="mb-6 font-montserrat text-base font-semibold leading-tight tracking-normal text-left">
                Repetir
              </p>
              <div className="flex flex-row justify-between items-center">
                {daysOfTheWeek.map((day) => {
                  return (
                    <SelectBox
                      selected={values.selectedDays.includes(day.id)}
                      title={day.title}
                      id={day.id}
                      onClick={() => handleSelectDay(day.id)}
                      key={day.id}
                    />
                  )
                })}
              </div>
            </div>
            <CustomSelect
              className="white-background"
              id="validity"
              label="Vigencia"
              name="validity"
              placeholder="validity"
              options={validity}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.validity}
              error={undefined}
              helperText={undefined}
            />
            <div className="flex w-full h-full justify-end content-end mt-12">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={!isValid || (!values.onSite && !values.remote)}
              >
                Guardar cambios
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddAvailabilityModal
