import { AxiosResponse } from 'axios'
import { http } from '../utils/http'
import { AvailabilityInputData, DoctorProfileData, MedicalProfileData, doctorFeeData } from '../utils/constants'


export const updateDoctorProfile = async (
  data: DoctorProfileData
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.patch('/api/doctor/profile', {
      ...data
    })

    return response
  } catch (error) {
    return error
  }
}

export const updateMedicalProfile = async (
  data: MedicalProfileData
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post('/api/doctor/medical-profile', {
      ...data
    })

    return response
  } catch (error) {
    return error
  }
}

export const getMedicalProfile = async () => {
  try {
    const response = await http.get('/api/doctor/medical-profile')
    return response.data
  } catch (error) {
    return error
  }
}

export const updateAvailability = async (
  data: AvailabilityInputData
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post('/api/doctor/availability-profile', {
      ...data
    })

    return response
  } catch (error) {
    return error
  }
}

export const getAvailability = async () => {
  try {
    const response = await http.get('/api/doctor/availability-profile')
    return response.data
  } catch (error) {
    return error
  }
}

export const getWebDoctors = async (doctorStatus: string) => {
  try {
    const response = await http.get(`/api/doctor/web`, {
      params: { doctorStatus }
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const enableDoctor = async (id: number, licence?: number) => {
  try {
    const response = await http.patch(
      `/api/doctor/${id}/enable`,
      licence !== undefined && {
        licence
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}

export const rejectDoctor = async (id: number, message: string) => {
  try {
    const response = await http.patch(`/api/doctor/${id}/reject`, {
      message
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const setDoctorFee = async (
  data: doctorFeeData
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post('/api/doctor/payments-profile', {
      ...data
    })

    return response
  } catch (error) {
    return error
  }
}

export const getDoctorFee = async () => {
  try {
    const response = await http.get('/api/doctor/payments-profile')
    return response.data
  } catch (error) {
    return error
  }
}

export const doctorActivation = async (id: number, isActive: boolean) => {
  try {
    const response = await http.patch(`/api/doctor/${id}/activation`, {
      isActive
    })
    return response
  } catch (error) {
    return error
  }
}
