import { TextField, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStore } from 'zustand'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CustomSelect } from '../../components/CustomSelect'
import { getHealthCoverage, getSpecialties } from '../../services/inputsData'
import { authStore } from '../../store/auth'
import { useInputDataStore } from '../../store/inputDataStore'
import { getMedicalProfile, updateMedicalProfile } from '../../services/doctor'
import { useDoctorStore } from '../../store/doctor'
import { useAlertStore } from '../../store/alertStore'
import { AxiosResponse } from 'axios'
import { MedicalProfileData } from '../../utils/constants'
import { InputData } from '../../utils/interfaces/stores'

export const OptionsMessage = () => {
  return (
    <p className='font-montserrat font-normal text-light-grey text-xs pt-2 pl-1'>
      Podes ingresar más de un servicios adicional
    </p>
  )
}

export const SelectedLabels = (props: {
  selectedValues: number[]
  data: InputData[]
}) => {
  const { selectedValues, data } = props
  return (
    <div className='flex flex-row w-full mt-2 flex-wrap gap-2'>
      {selectedValues.map((value: number) => {
        const label = data.find((v: InputData) => v.id === value)?.title
        return (
          <p
            key={value}
            className='font-montserrat font-normal text-pitch-black text-xs bg-yellow-light p-1 rounded-xl mr-1'
          >{`${label}`}</p>
        )
      })}
    </div>
  )
}

const MedicalProfileScreen = () => {
  const { user } = useStore(authStore)
  const { setAlert } = useAlertStore()
  const { medicalProfile, setMedicalProfile } = useDoctorStore()
  const { setSpecialties, setHealthCoverages, specialties, healthCoverages } =
    useInputDataStore()
  const [isLoading, setIsloading] = useState<boolean>(false)

  useEffect(() => {
    const apiCall = async () => {
      const data = await getMedicalProfile()
      setMedicalProfile(data)
    }

    setIsloading(true)
    apiCall().then(() => setIsloading(false))
  }, [setMedicalProfile])

  useEffect(() => {
    const apiCall = async () => {
      if (!specialties || specialties.length === 0) {
        const data = await getSpecialties()
        setSpecialties(data)
      }

      if (!healthCoverages || healthCoverages.length === 0) {
        const data = await getHealthCoverage()
        setHealthCoverages(data)
      }
    }

    setIsloading(true)
    apiCall().then(() => setIsloading(false))
  }, [setSpecialties, specialties, healthCoverages, setHealthCoverages])

  const validationSchema = Yup.object().shape({
    healthCoveragesIds: Yup.array().of(Yup.number()).nullable().notRequired(),
    specialtiesIds: Yup.array()
      .of(Yup.number().required())
      .min(1, 'Seleccione al menos un elemento')
      .required(),
    trajectory: Yup.string().max(700, 'El texto es demasiado largo'),
  })

  const initialValues: MedicalProfileData = {
    healthCoveragesIds: [],
    specialtiesIds: [],
    trajectory: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const response = await updateMedicalProfile(values)
      const { status } = response as AxiosResponse

      if (status === 201) {
        setAlert({
          isOpen: true,
          message: 'Datos actualizados.',
          severity: 'success',
        })
      } else {
        setAlert({
          isOpen: true,
          message:
            'Error en la actualización, por favor verifique los datos ingresados',
          severity: 'error',
        })
      }
    },
  })

  useEffect(() => {
    setFieldValue('healthCoveragesIds', medicalProfile.healthCoveragesIds)
    setFieldValue('specialtiesIds', medicalProfile.specialtiesIds)
    setFieldValue('trajectory', medicalProfile.trajectory)
  }, [medicalProfile, setFieldValue])

  useEffect(() => {
    validateForm()
  }, [validateForm])

  if (isLoading)
    return (
      <div className='flex flex-row h-full w-full pt-28 pl-10'>
        <p>Is Loading...</p>
      </div>
    )


  const aditionalOptions: InputData[] = [
    { id: 1, tag: 'Servicio 1', title: 'Servicio 1' },
    { id: 2, tag: 'Servicio 2', title: 'Servicio 2' },
    { id: 3, tag: 'Servicio 3', title: 'Servicio 3' },
    { id: 4, tag: 'Servicio 4', title: 'Servicio 4' },
    { id: 5, tag: 'Servicio 5', title: 'Servicio 5' }
  ]
  return (
    <div className='flex flex-row h-full w-full pt-28 pl-10'>
      <form onSubmit={handleSubmit}>
        {specialties && healthCoverages && (
          <div className='flex flex-col w-4/5'>
            <div className='flex flex-col w-full pb-48'>
              <div className='font-montserrat font-normal text-xl tracking-wider text-pitch-black pb-12'>
                Perfil entrevistador
              </div>
              <div className='flex flex-col pb-4'>
                <div className='flex flex-wrap w-full'>
                  <div className='w-full flex flex-row justify-between'>
                    <div className='w-96 pr-6 pb-4'>
                      <TextField
                        label='N° de matrícula'
                        variant='outlined'
                        className='w-full'
                        value={user?.licence}
                        disabled={true}
                      />
                    </div>
                    <div className='w-96 pl-6 pb-4 flex flex-col'>
                      <CustomSelect
                        id='specialtiesIds'
                        name='specialtiesIds'
                        value={values.specialtiesIds}
                        options={specialties}
                        label='Aptitudes'
                        placeholder='Aptitudes'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!errors.specialtiesIds && touched.specialtiesIds
                        }
                        helperText={
                          !!errors.specialtiesIds &&
                          touched.specialtiesIds !== undefined &&
                          (errors.specialtiesIds as string)
                        }
                        multiple
                      />
                      {values.specialtiesIds.length !== 0 ? (
                        <SelectedLabels
                          selectedValues={values.specialtiesIds}
                          data={specialties}
                        />
                      ) : (
                        <OptionsMessage />
                      )}
                    </div>
                  </div>
                  <div className='w-96 pr-6 flex flex-col'>
                    <div className='pb-4'>
                      <Typography
                        fontWeight='normal'
                        className='text-light-grey text-base'
                      >
                        En caso de ofrecer servicios adicionales, elegirlos aca:
                      </Typography>
                    </div>
                    <CustomSelect
                      id='healthCoveragesIds'
                      name='healthCoveragesIds'
                      value={values.healthCoveragesIds}
                      // options={healthCoverages}
                      options={aditionalOptions}
                      label='Servicios Adicionales'
                      placeholder='Servicios Adicionales'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!errors.healthCoveragesIds &&
                        touched.healthCoveragesIds
                      }
                      helperText={
                        !!errors.healthCoveragesIds &&
                        touched.healthCoveragesIds !== undefined &&
                        (errors.healthCoveragesIds as string)
                      }
                      multiple
                    />
                    {values.healthCoveragesIds.length !== 0 ? (
                      <SelectedLabels
                        selectedValues={values.healthCoveragesIds}
                        data={healthCoverages}
                      />
                    ) : (
                      <OptionsMessage />
                    )}
                  </div>
                </div>
                <div></div>
              </div>
              <div className='w-full pb-4'>
                <TextField
                  label='Contanos acerca de vos...'
                  variant='outlined'
                  multiline
                  rows={6}
                  inputProps={{
                    maxLength: 700,
                  }}
                  placeholder='Contanos un poco sobre tu formación y trayectoria profesional...'
                  id='trajectory'
                  className='w-full'
                  value={values.trajectory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.trajectory && touched.trajectory}
                  helperText={
                    !!errors.trajectory &&
                    touched.trajectory !== undefined &&
                    errors.trajectory
                  }
                />
              </div>
            </div>
            <div className='w-full flex flex-row justify-end pb-8'>
              <Button
                type='submit'
                disabled={!isValid}
                variant='contained'
                color='secondary'
              >
                Guardar cambios
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default MedicalProfileScreen
