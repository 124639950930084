import React, { useEffect, useState } from 'react'
import MedappLogo from '../../assets/logo-completo.png'

const TermsAndConditions = () => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    fetch('/profesional/terms-and-conditions.html')
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html)
      })
  }, [])

  return (
    <div className='flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4'>
      <div className='flex flex-col justify-around items-center bg-white md:py-6 mx-4 md:w-3/5 rounded-xl md:max-h-[80vh] max-h-[90vh]'>
        <div className='flex md:pt-0 pt-6'>
          <img
            src={MedappLogo}
            alt='intermedia login logo'
            className=' mb-8 w-34 h-24'
          ></img>
        </div>
        <div className='flex pt-6 flex-col overflow-auto'>
          <div className='md:px-16 px-4' dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
