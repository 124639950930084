import { useEffect, useState } from 'react'
import { Button, IconButton, Modal, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAlertStore } from '../../../store/alertStore'
import { useCalendarStore } from '../../../store/calendarStore'
import FormatedDateBox from './FormatedDateBox'
import VideocamIcon from '@mui/icons-material/Videocam'
import CustomModal from '../../../components/customModal'
import ModalCross from '../../../assets/modal-cross.png'
import {
  cancelAppointment,
  getAppointment
} from '../../../services/appointments'
import { AppointmentSlotType } from '../../../utils/constants'
import { getWeekDateRange } from '../utils'
import { getWeeklyData } from '../../../services/availability'
import { Appointment } from '../../../utils/constants'
import { SelectedModalProps } from '../../../utils/interfaces/props'

const AppointmentDetailsModal = (props: SelectedModalProps) => {
  const { open, handleClose, selected, resetSelected, currentDate } = props
  const { setWeeklyData } = useCalendarStore()
  const { setAlert } = useAlertStore()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [data, setAppointmentData] = useState<Appointment | null>(null)
  const [note, setNote] = useState<string>('')
  const [error, setError] = useState<string>('')
  const appointmentId =
    typeof selected[1]! === 'number' ? selected[1]! : parseInt(selected[1]!)

  useEffect(() => {
    const apiCall = async () => {
      const response = await getAppointment(appointmentId)
      setAppointmentData(response)
    }

    if (open) apiCall()
  }, [appointmentId, open])

  const submit = async () => {
    const response = await cancelAppointment(appointmentId, note)

    if (response.status !== 200) {
      setAlert({
        isOpen: true,
        message: 'Error al cancelar el turno',
        severity: 'error'
      })
      return
    }
    if (response.status === 200) {
      setAlert({
        isOpen: true,
        message: 'Se canceló éxitosamente el turno',
        severity: 'success'
      })
      resetSelected()
      handleClose()
      setOpenModal(false)

      const { startDate, endDate } = getWeekDateRange(currentDate!)
      const weeklyData = await getWeeklyData(startDate, endDate)
      setWeeklyData(weeklyData.items)
    }
  }

  const isValidNote = () => {
    if (note.length >= 200) {
      setError('El mensaje es demasiado largo')
      return false
    } else {
      setError('')
      return true
    }
  }

  return (
    <Modal open={open} className="flex items-center justify-center">
      <div className="flex flex-col w-[600px] h-auto bg-white rounded">
        <div className="flex flex-row w-full items-start justify-end">
          <IconButton aria-label="close" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="flex flex-col px-6 ">
          <Typography
            className={`tracking-wider`}
            fontSize={24}
            fontWeight="bold"
          >
            Detalle del Turno
          </Typography>
          {data && (
            <>
              <div className="mt-4">
                <Typography
                  className={`tracking-wider text-aqua-intense`}
                  fontWeight="bold"
                >
                  {data.specialty.title}
                </Typography>
              </div>
              <div className="flex items-center">
                <Typography
                  className={`tracking-wider text-light-grey`}
                  fontWeight="bold"
                >
                  {data.patient.firstName} {data.patient.lastName} |
                </Typography>
                <div className="ml-1">
                  <Typography className={`tracking-wider text-light-grey`}>
                    {data.healthCoverage
                      ? data.healthCoverage.title
                      : 'Sin cobertura'}
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row items-center mt-2">
                <FormatedDateBox key={'id'} startDate={data.startDate} />
                <div className="flex flex-row items-center mt-3 ml-3">
                  <VideocamIcon className="text-light-grey" />
                  <div className="ml-2">
                    <Typography className={`tracking-normal`} fontSize={14}>
                      {data.appointmentType === AppointmentSlotType.videocall
                        ? 'Videollamada'
                        : 'Presencial'}
                    </Typography>
                  </div>
                </div>
              </div>
              {!data.healthCoverage && (
                <div className="flex flex-row mt-1 items-center">
                  <div className="mr-2">
                    <Typography fontWeight="bold">
                      Valor de la consulta:
                    </Typography>
                  </div>
                  <div>
                    <Typography>${data.appointmentFee}</Typography>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="flex flex-row w-full h-full justify-center space-x-6 mt-8 mb-6">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleClose()}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenModal(true)}
            >
              Cancelar Turno
            </Button>
          </div>
        </div>
        <CustomModal
          openModal={openModal}
          handleClose={() => {
            setOpenModal(false)
          }}
          imgSrc={ModalCross}
          title="Cancelar Turno"
          description=""
          onSubmit={() => submit()}
          submitLabel="Confirmar"
          showCancelButton={true}
          isSubmitDisable={error !== ''}
          child={
            <div className="w-full pb-4 mt-[-15px]">
              <div className="w-full flex flex-col items-center pb-4">
                <p className="font-bold text-sm">
                  ¿Estás seguro que querés cancelar el turno?
                </p>
                <p className="text-sm text-center">
                  A continuación podes enviarle un mensaje al cliente de manera
                  opcional.
                </p>
              </div>
              <TextField
                label="Mensaje..."
                variant="outlined"
                multiline
                rows={6}
                inputProps={{
                  maxLength: 700
                }}
                id="trajectory"
                className="w-full white-background"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value)
                  isValidNote()
                }}
                onBlur={() => isValidNote()}
                error={error !== ''}
                helperText={error}
              />
            </div>
          }
        />
      </div>
    </Modal>
  )
}

export default AppointmentDetailsModal
