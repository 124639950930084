import React, { useEffect, useState } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import MedappLogo from '../../assets/logo-completo.png'
import { useNavigate, useSearchParams, } from 'react-router-dom'
import { callback } from '../../services/mercadoPago'
import { useAlertStore } from '../../store/alertStore'

const MercadoPagoLanding = () => {
  const [text, setText] = useState("Estamos procesando los datos de Mercado Pago...")
  const [redirectTimeout, setRedirectTimeout] = useState<NodeJS.Timeout>()
  const [textTimeout, setTextTimeout] = useState<NodeJS.Timeout>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { setAlert } = useAlertStore()


  useEffect(() => {
    /**
     * Function to process data from Mercado Pago.
     */
    const processData = async () => {
      const code = searchParams.get('code')
      const state = searchParams.get('state')

      if (code && state) {
        const response = await callback(code, state)
        if ((response as any).status === 200) {
          // Set timeout to change display text
          const textTimeout = setTimeout(() => {
            setText('Redireccionando...')
          }, 1500)
          // Set timeout to redirect user to profile page
          const redTimeout = setTimeout(() => {
            navigate('/profile', {
              replace: true,
              state: {
                goToPayments: true
              }
            })
          }, 2000)
          // Set timeout states
          setTextTimeout(textTimeout)
          setRedirectTimeout(redTimeout)
        } else {
          setAlert({
            isOpen: true,
            message: (response as any).response.data.message,
            severity: 'error'
          })
          setText('Ups ocurrió un error! Será redireccionado a su perfil')
          const redTimeout = setTimeout(() => {
            navigate('/profile', {
              replace: true,
              state: {
                goToPayments: true
              }
            })
          }, 2000)
          setRedirectTimeout(redTimeout)
        }
      }
    }

    processData()
    // Clear timeout on unmount
    return () => {
      clearTimeout(textTimeout)
      clearTimeout(redirectTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, navigate])

  return (
    <div className="flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4">
      <div className="flex flex-col justify-around items-center bg-white md:px-16 px-2 md:py-6 mx-4 md:w-3/5 max-w-xl rounded-xl">
        <img
          src={MedappLogo}
          alt="intermedia login logo"
          className="login-logo mb-8"
        ></img>
        <div className='flex'>
          <CircularProgress sx={{ color: '#D3D3D3' }} />
        </div>
        <div className='flex w-full justify-center mt-12'>
          <Typography>{text}</Typography>
        </div>
      </div>
    </div>
  )
}

export default MercadoPagoLanding
