import ProfileForm from './profileForm'

const PersonalDataScreen = () => {
  
  return (
    <div className="flex flex-row h-full w-full pt-28 pl-10">
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col h-2/3 w-5/6 border-b border-solid border-[#D3D3D3] justify-start">
          <ProfileForm />
        </div>
      </div>
    </div>
  )
}

export default PersonalDataScreen
