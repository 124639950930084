import { Layout } from '../../components/Layout'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'
import { UserTypes } from '../../utils/constants'
import DoctorWelcome from './components/DoctorWelcome/DoctorWelcome'
import AdminWelcome from './components/AdminWelcome'

const WelcomeScreen = () => {
  const { user } = useStore(authStore)
  return (
    <Layout>
      {user?.role === UserTypes.doctor ? (
        <DoctorWelcome />
      ) : (
        <AdminWelcome />
      )}
    </Layout>
  )
}

export default WelcomeScreen
