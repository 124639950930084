import { useState, useEffect } from 'react'
import { Layout } from '../../components/Layout'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import {
  GridColDef,
  GridColumnHeaderParams
} from '@mui/x-data-grid'
import UserAvatar from '../../components/UserAvatar'
import { useNavigate } from 'react-router-dom'
import NothingToShow from '../../components/nothingToShow'
import CustomSearchBox from '../../components/CustomSearchBox'
import EmptyAvatar from '../../assets/avatar_circular.png'
import { Typography } from '@mui/material'
import { getRapports } from '../../services/rapports'
import { RapportProps, RapportResponse } from '../../utils/constants'
import { useRapportsStore } from '../../store/rapportsStore'
import CustomDataGrid from '../../components/CustomDataGrid'

const RapportsInitialScreen = () => {
  const [searchText, setSearchText] = useState<string>('')
  const navigate = useNavigate()
  const { rapports, setRapports } = useRapportsStore()

  useEffect(() => {
    const apiCall = async () => {
      const response = await getRapports()
      setRapports(
        response.map((rapport: RapportResponse) => {
          const { id, patient } = rapport
          return {
            id,
            lastName: patient.lastName,
            firstName: patient.firstName,
            email: patient.email,
            profilePictureUrl: patient.profilePictureUrl
          }
        })
      )
    }
    apiCall()
  }, [setRapports])

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Cliente',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Cliente
          </Typography>
        )
      },
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center pl-4">
            <UserAvatar imageUrl={cellValues.row.profilePictureUrl !== undefined ? cellValues.row.profilePictureUrl : EmptyAvatar} size={44} altText={'IMG'} />
            <Typography className="pl-8">
              {cellValues.row.firstName} {cellValues.row.lastName}
            </Typography>
          </div>
        )
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Email
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <Typography className="pl-4">{cellValues.row.email}</Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'hypertext',
      headerName: '',
      editable: false,
      renderHeader: (params: GridColumnHeaderParams): any => {
        return <div className="pl-4"></div>
      },
      renderCell: (cellValues): any => {
        return (
          <Typography
            onClick={() =>
              navigate(`/info/${cellValues.row.id}`, {
                replace: true,
                state: {
                  rapportDetails: cellValues.row,
                  id: cellValues.row.id
                }
              })
            }
            className="tracking-wider text-center text-sm text-aqua-intense underline cursor-pointer"
            fontWeight="bold"
          >
            más info
          </Typography>
        )
      },
      flex: 0.25,
      sortable: false
    }
  ]

  const filterRow = (row: RapportProps) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase())

  return (
    <Layout>
      <div className="flex flex-col w-full h-full pb-8">
        <div className="flex w-full items-center">
          <div className="flex w-1/5 justify-start pl-2 pr-2">
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight="bold"
            >
              Información
            </Typography>
          </div>
          <div className="flex w-4/5 justify-end pl-2 pr-2">
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
          </div>
        </div>
        <div className="bg-white mx-14">
          <div
            className={`flex mr-4 ${rapports.length !== 0 ? 'h-[495px]' : 'pb-12'
              }`}
          >
            {(searchText.length && rapports.filter(filterRow).length === 0) ||
              rapports.length === 0 ? (
              <NothingToShow
                nothingImg={nothingToShowImg}
                altText={'sin data de cliente'}
                nothingText={'No tenés nada que ver hasta el momento...'}
              />
            ) : (
              <CustomDataGrid
                rows={
                  searchText.length ? rapports.filter(filterRow) : rapports
                }
                rowHeight={64}
                columns={columns}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RapportsInitialScreen
