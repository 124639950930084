import { useEffect, useState } from 'react'
import { Button, Paper } from '@mui/material'
import { ViewState } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments
} from '@devexpress/dx-react-scheduler-material-ui'
import CircleIcon from '@mui/icons-material/Circle'
import VideocamIcon from '@mui/icons-material/Videocam'
import HomeIcon from '@mui/icons-material/Home'
import { DayScaleCell, StyledMonthViewTimeTableCell } from './MonthView'
import CalendarHeader from './CalendarHeader'
import {
  DayScaleEmptyCell,
  DayScaleRow,
  TimeScaleLayout,
  WeekDayScaleCell,
  WeekTimeTableCell
} from './WeekView'
import { classes, StyledAppointment } from './common'
import AddAvailabilityModal from './AddAvailabilityModal'
import EditAvailabilityModal from './EditAvailabilityModal'
import {
  AppointmentSlotType,
  AvailabilitySlotType
} from '../../../utils/constants'
import { getMonthlyData, getWeeklyData } from '../../../services/availability'
import { useCalendarStore } from '../../../store/calendarStore'
import { getWeekDateRange, getMonthDateRange } from '../utils'
import { useDoctorStore } from '../../../store/doctor'
import CustomModal from '../../../components/customModal'
import ModalCross from '../../../assets/modal-cross.png'
import DeleteAvailabilityModal from './DeleteAvailabilityModa'
import AppointmentDetailsModal from './AppointmentDetailsModal'

const { Appointment } = Appointments

const Calendar = () => {
  const [view, setView] = useState('monthly')
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const [deleteAppointmentIsOpen, setDeleteAppointmentIsOpen] =
    useState<boolean>(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false)
  const { weeklyData, setWeeklyData, monthlyData, setMonthlyData } =
    useCalendarStore()
  const [selectedAppointment, setSelectedAppointment] = useState<
    Array<string | number | undefined>
  >([])
  const [selectedType, setSelectedType] = useState<AvailabilitySlotType | null>(
    null
  )
  const { availability } = useDoctorStore()

  useEffect(() => {
    const { startDate, endDate } =
      view === 'monthly'
        ? getMonthDateRange(currentDate)
        : getWeekDateRange(currentDate)
    const apiCall = async () => {
      if (view === 'monthly') {
        const response = await getMonthlyData(startDate, endDate)
        setMonthlyData(response)
      } else {
        const response = await getWeeklyData(startDate, endDate)
        setWeeklyData(response.items)
      }
    }

    apiCall()
  }, [currentDate, setMonthlyData, setWeeklyData, view])

  useEffect(() => {
    const { startDate, endDate } = getMonthDateRange(currentDate)
    const apiCall = async () => {
      const response = await getMonthlyData(startDate, endDate)
      setMonthlyData(response)
    }
    apiCall()
  }, [currentDate, setMonthlyData, weeklyData])

  useEffect(() => {
    setSelectedAppointment([])
  }, [view, setSelectedAppointment])

  const resetSelected = () => {
    setSelectedAppointment([])
    setSelectedType(null)
  }

  const TimeTableCell = (props: MonthView.TimeTableCellProps) => {
    const { startDate } = props
    const date = new Date(startDate)

    const onClick = () => {
      if (date.getDay() !== 0) {
        setCurrentDate(new Date(date))
        setView('weekly')
      }
    }

    return (
      <StyledMonthViewTimeTableCell
        onClick={onClick}
        {...props}
        className={
          date.getDay() === 0 ? classes.weekendCell : classes.weekdayCell
        }
      />
    )
  }

  const MonthAppointment = (props: Appointments.AppointmentProps) => {
    const { data } = props
    const onClick = () => {
      setCurrentDate(new Date(data.startDate))
      setView('weekly')
    }

    return (
      <Appointment onClick={onClick} {...props} className="bg-white">
        <div className="flex w-full h-full bg-white justify-center">
          {data.isAvailable && <CircleIcon sx={{ color: '#C2D400' }} />}
          {data.hasAppointment && <CircleIcon sx={{ color: '#FFA800' }} />}
        </div>
      </Appointment>
    )
  }

  const onClickAppointment = (event: Appointments.AppointmentProps) => {
    const {
      id: selectedId,
      availabilitySlotType,
      appointmentId: existingAppointmentId
    } = event.data

    if (selectedAppointment.includes(selectedId)) {
      // The selected appointment or availability is already in the list
      if (existingAppointmentId) {
        // There is an existing appointment, so we clear the selection
        setSelectedType(null)
        setSelectedAppointment([])
      } else {
        // There is an existing abailability, so we remove the selected availability from the list
        if (selectedAppointment.length === 1) {
          // This was the last selected availability, so we clear the type as well
          setSelectedType(null)
        }
        setSelectedAppointment(
          selectedAppointment.filter(
            (appointmentId) => appointmentId !== selectedId
          )
        )
      }
    } else {
      // The selected appointment or availability is not in the list
      setSelectedType(availabilitySlotType)
      if (existingAppointmentId) {
        // There is an existing appointment, so we replace the selected appointment to the list
        setSelectedAppointment([selectedId, existingAppointmentId])
      } else {
        // There is an existing availability, so we add the selected appointment to the list
        setSelectedAppointment([...selectedAppointment, selectedId])
      }
    }
  }

  const WeekAppointment = (props: Appointments.AppointmentProps) => {
    const { data } = props
    const baseStyle =
      'flex w-full h-full justify-center items-center bg-opacity-25'

    return (
      <StyledAppointment
        onClick={
          selectedType === null || selectedType === data.availabilitySlotType
            ? onClickAppointment
            : () => null
        }
        {...props}
        className={
          selectedAppointment.includes(data.id)
            ? data.appointmentId
              ? classes.selectedAppointment
              : classes.selectedAvailability
            : selectedType === null ||
              selectedType === data.availabilitySlotType
            ? classes.appointment
            : classes.disabledAppointment
        }
      >
        {!data.appointmentId && (
          <div className={`${baseStyle} bg-green flex-row space-x-6`}>
            {data.availabilitySlotType === AvailabilitySlotType.videocall && (
              <VideocamIcon className="text-green" />
            )}
            {data.availabilitySlotType === AvailabilitySlotType.presential && (
              <HomeIcon className="text-green opacity-100" />
            )}
            {data.availabilitySlotType ===
              AvailabilitySlotType.presentialVideocall && (
              <>
                <VideocamIcon className="text-green opacity-100" />
                <HomeIcon className="text-green opacity-100" />
              </>
            )}
          </div>
        )}
        {data.appointmentId &&
          data.appointmentType === AppointmentSlotType.videocall && (
            <div className={`${baseStyle} bg-orange`}>
              <VideocamIcon sx={{ color: '#FFA800' }} />
            </div>
          )}
        {data.appointmentId &&
          data.appointmentType === AppointmentSlotType.presential && (
            <div className={`${baseStyle} bg-orange`}>
              <HomeIcon sx={{ color: '#FFA800' }} />
            </div>
          )}
      </StyledAppointment>
    )
  }

  return (
    <>
      <div className="flex flex-row w-full items-center justify-between ">
        <div className="font-montserrat text-pitch-black font-bold text-2xl tracking-wider">
          Disponibilidad
        </div>
        {selectedAppointment && selectedAppointment.length > 0 ? (
          weeklyData.find(
            (a) =>
              a.id === selectedAppointment[0] &&
              weeklyData.find((a) => a.id === selectedAppointment[0])!
                .appointmentId
          ) ? (
            <Button
              variant="text"
              color="error"
              onClick={() => setDeleteAppointmentIsOpen(true)}
            >
              Cancelar turno
            </Button>
          ) : (
            <div className="space-x-3">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setEditModalIsOpen(true)}
              >
                Editar disponibilidad
              </Button>
              <Button
                variant="text"
                color="error"
                onClick={() => setDeleteModalIsOpen(true)}
              >
                Eliminar disponibilidad
              </Button>
            </div>
          )
        ) : (
          <Button
            color="secondary"
            variant="contained"
            className="w-[238px]"
            onClick={() => setModalIsOpen(true)}
          >
            Agregar disponibilidad
          </Button>
        )}
      </div>

      <div className="rounded-lg bg-white mt-4 w-full h-[723px]">
        <CalendarHeader
          view={view}
          setView={setView}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
        <Paper className="px-6 mt-4" sx={{ backgroundColor: '#FFFFFF' }}>
          <Scheduler
            firstDayOfWeek={0}
            locale={'es'}
            data={view === 'weekly' ? weeklyData : monthlyData}
          >
            <ViewState currentDate={currentDate} />
            {view === 'monthly' && (
              <MonthView
                dayScaleCellComponent={DayScaleCell}
                timeTableCellComponent={TimeTableCell}
              />
            )}
            {view === 'weekly' && (
              <WeekView
                startDayHour={4}
                endDayHour={23}
                dayScaleCellComponent={WeekDayScaleCell}
                dayScaleRowComponent={DayScaleRow}
                timeTableCellComponent={WeekTimeTableCell}
                timeScaleLayoutComponent={TimeScaleLayout}
                dayScaleEmptyCellComponent={DayScaleEmptyCell}
              />
            )}
            <Appointments
              appointmentComponent={
                view === 'weekly' ? WeekAppointment : MonthAppointment
              }
            />
          </Scheduler>
        </Paper>
      </div>
      <AddAvailabilityModal
        open={
          modalIsOpen &&
          (availability.presentialAppointments ||
            availability.videocallAppointments)
        }
        handleClose={() => setModalIsOpen(false)}
      />
      {(modalIsOpen || editModalIsOpen) && (
        <CustomModal
          openModal={
            (modalIsOpen || editModalIsOpen) &&
            !(
              availability.presentialAppointments ||
              availability.videocallAppointments
            )
          }
          handleClose={() => {
            setModalIsOpen(false)
            setEditModalIsOpen(false)
          }}
          imgSrc={ModalCross}
          title="Error"
          description="Debe configurar su disponibilidad en el perfil."
          onSubmit={() => {
            setModalIsOpen(false)
            setEditModalIsOpen(false)
          }}
          submitLabel="Aceptar"
          showCancelButton={false}
          child={null}
          isSubmitDisable={null}
        />
      )}
      <EditAvailabilityModal
        open={
          editModalIsOpen &&
          (availability.presentialAppointments ||
            availability.videocallAppointments)
        }
        handleClose={() => setEditModalIsOpen(false)}
        selected={selectedAppointment}
        availabilities={weeklyData}
        resetSelected={resetSelected}
        selectedType={selectedType}
      />
      <DeleteAvailabilityModal
        open={deleteModalIsOpen}
        handleClose={() => setDeleteModalIsOpen(false)}
        selected={selectedAppointment}
        resetSelected={resetSelected}
      />
      <AppointmentDetailsModal
        open={deleteAppointmentIsOpen}
        handleClose={() => setDeleteAppointmentIsOpen(false)}
        selected={selectedAppointment}
        resetSelected={resetSelected}
        currentDate={currentDate}
      />
    </>
  )
}

export default Calendar
