import React from 'react'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import {
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MenuIcon from '@mui/icons-material/Menu'
import { CustomToolbar } from '../components/CustomToolbar'
import HomeIcon from '@mui/icons-material/Home'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import DescriptionIcon from '@mui/icons-material/Description'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useNavigate, useLocation } from 'react-router-dom'
import TooltipIcon from '../components/TooltipIcon'
import { useStore } from 'zustand'
import { authStore } from '../store/auth'
import { doctorStatuses, UserTypes } from '../utils/constants'
import packageJson from '../../package.json'
import MedappIsotipoIcon from '../components/icons/MedappIsotipoIcon'
import StethoscopeIcon from '../components/icons/StethoscopeIcon'
import RapportIcon from '../components/icons/RapportIcon'
import { useTheme } from '@mui/material'
import { userState } from '../utils/interfaces/stores'
import { DrawerProps } from '../utils/interfaces/props'
import ReportsIcon from '../components/icons/ReportsIcon'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1)
}))

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7) as string} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8) as string} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
  // @ts-expect-error
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

export const CustomDrawer = ({ open, toogleDrawer }: DrawerProps) => {
  const { user } = useStore(authStore)
  const { doctorStatus, role } = user as userState
  const { admin, superAdmin, doctor } = UserTypes
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()

  const { APPROVAL_PENDING } = doctorStatuses
  const OPTION_SELECTED_STYLE = 'text-aqua-intense'
  const OPTION_DEFAULT_STYLE = 'text-black'

  const getClassName = (pathname: string) =>
    location.pathname === pathname
      ? OPTION_SELECTED_STYLE
      : OPTION_DEFAULT_STYLE
  const getFillForSVG = (pathname: string) =>
    location.pathname === pathname
      ? theme.palette.primary.dark
      : theme.palette.primary.contrastText

  const goToPage = (path: string) => {
    if (open) {
      toogleDrawer()
    }

    navigate(path, { replace: true })
  }

  const itemsList = [
    {
      title: (
        <Typography className={getClassName('/welcome')}>Inicio</Typography>
      ),
      shouldRender: true,
      key: 'inicio',
      action: () => goToPage('/welcome'),
      icon: (
        <TooltipIcon
          icon={<HomeIcon className={getClassName('/welcome')} />}
          label="Inicio"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/turnos')}>Turnos</Typography>
      ),
      shouldRender: role === doctor && doctorStatus !== APPROVAL_PENDING,
      key: 'turnos',
      action: () => goToPage('/turnos'),
      icon: (
        <TooltipIcon
          icon={<InsertInvitationIcon className={getClassName('/turnos')} />}
          label="Turnos"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/reports')}>Reportes</Typography>
      ),
      shouldRender: role === doctor && doctorStatus !== APPROVAL_PENDING,
      key: 'reportes',
      action: () => goToPage('/reports'),
      icon: (
        <TooltipIcon
          icon={
            <div>
              <ReportsIcon fill={getFillForSVG('/reports')} />
            </div>
          }
          label="Reportes"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/admin/doctors')}>
          Profesionales
        </Typography>
      ),
      shouldRender: role === admin || role === superAdmin,
      key: 'medicos',
      action: () => goToPage('/admin/doctors'),
      icon: (
        <TooltipIcon
          icon={
            <div>
              <StethoscopeIcon fill={getFillForSVG('/admin/doctors')} />
            </div>
          }
          label="Profesionales"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/admin/reports')}>
          Reportes
        </Typography>
      ),
      shouldRender: role === admin || role === superAdmin,
      key: 'admin-reports',
      action: () => goToPage('/admin/reports'),
      icon: (
        <TooltipIcon
          icon={
            <div>
              <ReportsIcon fill={getFillForSVG('/admin/reports')} />
            </div>
          }
          label="Reportes"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/info')}>Información</Typography>
      ),
      shouldRender: role === doctor && doctorStatus !== APPROVAL_PENDING,
      key: 'info',
      action: () => goToPage('/info'),
      icon: (
        <TooltipIcon
          icon={
            <div>
              <RapportIcon fill={getFillForSVG('/info')} />
            </div>
          }
          label="Información"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/availability')}>
          Disponibilidad
        </Typography>
      ),
      shouldRender: role === doctor && doctorStatus !== APPROVAL_PENDING,
      key: 'disponibilidad',
      action: () => goToPage('/availability'),
      icon: (
        <TooltipIcon
          icon={<DescriptionIcon className={getClassName('/availability')} />}
          label="Disponibilidad"
        />
      )
    },
    {
      title: (
        <Typography className={getClassName('/extras')}>Extras</Typography>
      ),
      shouldRender:
        /* role === doctor && doctorStatus !== APPROVAL_PENDING*/ false,
      key: 'extras',
      action: () => undefined,
      icon: (
        <TooltipIcon
          icon={<LocalOfferIcon className={getClassName('/extras')} />}
          label="Extras"
        />
      )
    },
    {
      title: <Typography className="text-black">Administradores</Typography>,
      shouldRender: role === superAdmin,
      key: 'administrators',
      action: () => goToPage('/admin/admins'),
      icon: (
        <TooltipIcon
          icon={
            <div>
              <MedappIsotipoIcon fill={getFillForSVG('/admin/admins')} />
            </div>
          }
          label="Administradores"
        />
      )
    },
    // {
    //   title: <Typography className="text-black">Ayuda</Typography>,
    //   shouldRender: true,
    //   key: 'help',
    //   action: () => {},
    //   icon: (
    //     <TooltipIcon
    //       icon={<HelpOutlineOutlinedIcon className="text-black" />}
    //       label="Ayuda"
    //     />
    //   )
    // },
    {
      title: 'Cerrar sesión',
      shouldRender: true,
      key: 'logout',
      action: () => {
        goToPage('/logout')
      },
      icon: (
        <TooltipIcon
          icon={<LogoutOutlinedIcon className="text-black" />}
          label="Cerrar sesión"
        />
      )
    }
  ]

  const filteredItems = itemsList.filter((item) => item.shouldRender)

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <CustomToolbar open={open} />
      </DrawerHeader>
      <List>
        <ListItem
          key="open"
          sx={{
            padding: '23px 0px 23px 0px'
          }}
        >
          <ListItemButton onClick={() => toogleDrawer()}>
            <ListItemIcon>
              {open ? (
                <TooltipIcon
                  icon={<MenuOpenIcon className="text-black" />}
                  label="Cerrar"
                />
              ) : (
                <TooltipIcon
                  icon={<MenuIcon className="text-black" />}
                  label="Abrir"
                />
              )}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {filteredItems.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {((role === superAdmin && item.key === 'administrators') ||
                (role !== superAdmin && item.key === 'help')) && <Divider />}
              <ListItem
                key={item.key}
                sx={{
                  padding: '2px 0px 6px 0px'
                }}
              >
                <ListItemButton onClick={item.action}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText secondary={item.title} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          )
        })}
      </List>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div className="w-full flex justify-center absolute bottom-0 pb-4">
          <Typography className="text-secondary-light-grey" fontSize="small">
            {open ? 'Versión:' : ''} {packageJson.version}
          </Typography>
        </div>
      )}
    </Drawer>
  )
}
