import { NotificationProps } from './interfaces/props'
import { InputData } from './interfaces/stores'

export const doctorStatuses = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  ENABLED: 'ENABLED',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED'
}

export enum UserTypes {
  patient = 'PATIENT',
  doctor = 'DOCTOR',
  admin = 'ADMIN',
  superAdmin = 'SUPERADMIN'
}

export enum AvailabilitySlotType {
  videocall = 'VIDEOCALL',
  presential = 'PRESENTIAL',
  presentialVideocall = 'PRESENTIAL_VIDEOCALL'
}

export enum AppointmentSlotType {
  videocall = 'VIDEOCALL',
  presential = 'PRESENTIAL'
}

/** NOTIFICATIONS */

export interface NotificationsStoreProps extends NotificationProps {
  contentType: string
  patientId: number
  doctorId: number
  adminId: number
  availabilitySlotId: number
  appointmentId: number
  rapportNoteId: number
}

/** APPOINTMENT */

type Specialty = {
  id: number
  title: string
  tag: string
}

type HealthCoverage = {
  id: number
  title: string
  tag: string
}

enum AppointmentStatus {
  BOOKED = 'BOOKED',
  CANCELLED_DOCTOR = 'CANCELLED_DOCTOR',
  CANCELLED_PATIENT_REFUND = 'CANCELLED_PATIENT_REFUND',
  CANCELLED_PATIENT_NO_REFUND = 'CANCELLED_PATIENT_NO_REFUND',
  CONFIRMED = 'CONFIRMED'
}

type Patient = {
  firstName: string
  lastName: string
}

export type Appointment = {
  id: number
  startDate: string
  startText: string
  specialty: Specialty
  healthCoverage: HealthCoverage
  patient: Patient
  appointmentFee: number
  appointmentType: AppointmentSlotType
  appointmentStatus: AppointmentStatus
  cancelledAt?: string
}

/** RAPPORTS */
export interface RapportProps {
  id: number
  lastName: string
  firstName: string
  email: string
  profilePictureUrl?: string
}

export interface RapportResponse {
  id: number
  patient: {
    id: string
    lastName: string
    firstName: string
    email: string
    profilePictureUrl?: string
  }
}

export interface HistoryProsp {
  appointmentId: number
  appointmentType: string
  appointmentDate: string
  appointmentDateText: string
  specialty: {
    id: number
    tag: string
    title: string
  }
}

export interface NoteProps {
  id: number
  rapportId: number
  note: string
  createdAt: string
  updatedAt: string
}
export interface medicalRecordsDetailProps {
  description: string
  date: string
}

export interface MedicalRecordsProps {
  id: number
  recordType: string
  detail: medicalRecordsDetailProps
}

export interface RapportDetails {
  id: number
  firstName: string
  lastName: string
  email: string
  notes: NoteProps[]
  history: HistoryProsp[]
  medicalRecords: MedicalRecordsProps[]
}

export const medicalRecordsArray = [
  {
    id: 1,
    recordType: 'Medicacion',
    detail: {
      description: 'Levotiroxina marca GlaxoSmithKline (es la de color rosa)',
      date: '29/03/23'
    }
  },
  {
    id: 2,
    recordType: 'Patologia',
    detail: {
      description: 'Levotiroxina marca GlaxoSmithKline (es la de color rosa)',
      date: '30/03/23'
    }
  },
  {
    id: 3,
    recordType: 'Procedimiento quirurgico',
    detail: {
      description: 'Levotiroxina marca GlaxoSmithKline (es la de color rosa)',
      date: '31/03/23'
    }
  }
]
export interface DoctorProfileData {
  firstName?: string
  lastName?: string
  phone?: string
}

export interface MedicalProfileData {
  healthCoveragesIds: number[]
  specialtiesIds: number[]
  trajectory: string
}

export interface doctorFeeData {
  presentialFee: number
  videocallFee: number
}

export interface AvailabilityData {
  presentialAppointments: boolean
  homeAppointments: boolean
  doctorAddress?: {
    street: string
    streetNumber: number | string
    floorApartment: string
    province: InputData
    city: InputData
  }
  videocallAppointments: boolean
}

export interface AvailabilityInputData {
  presentialAppointments: boolean
  homeAppointments: boolean
  doctorAddress: {
    street: number
    streetNumber: number
    floorApartment: string
    provinceId: number
    cityId: number
  }
  videocallAppointments: boolean
}

export interface DoctorData {
  id: number
  licence: number
  firstName: string
  lastName: string
  phone: string
  email: string
  doctorStatus: string
  isActive: boolean
}

export interface PasswordFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

export type AddAvailabilityModalInitValueTypes = {
  remote: boolean
  onSite: boolean
  date: Date
  startHour: string
  endHour: string
  selectedDays: string[]
  validity: string
}

export interface ReportsItem {
  id: number
  firstName: string
  lastName: string
  profilePictureUrl: string
  email: string
}

export interface NewAvailabilityDto {
  startDate: Date
  endDate: Date
  availabilitySlotType: AvailabilitySlotType
  rrule?: string
}

export interface EditAvailabilityDto {
  availabilitySlotsIds: Array<string | number | undefined>
  availabilitySlotType: AvailabilitySlotType
}

export const APPOINTMENT_STATE_LABELS = {
  RESERVADO: 'Reservado',
  CANCELADO: 'Cancelado',
  REALIZADO: 'Realizado'
}

//REPORTS

export const modalidades = [
  { id: 0, title: 'Videollamada' },
  { id: 1, title: 'Presencial' }
]

export const coberturas = [
  { id: '0', title: 'Sin cobertura' },
  { id: '1', title: 'Con cobertura' }
]

export const estados = [
  { id: 0, title: 'Realizado' },
  { id: 1, title: 'Reservado' },
  { id: 2, title: 'Cancelado' }
]
