import { useTheme } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import { Checkbox, TextField } from '@mui/material'
import { CustomSelectProps } from '../utils/interfaces/props'

function getStyles (name: string, selectValues: string[], theme: any) {
  return {
    fontWeight:
      selectValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

interface Options {
  title: string
  id: number | string
  tag: string
}

export const CustomSelect = (props: CustomSelectProps) => {
  const { options, multiple, value, className } = props
  const theme = useTheme()

  return (
    <TextField
      select
      variant="outlined"
      className={className ? className : 'w-full'}
      SelectProps={{
        multiple,
        value,
        renderValue: (selected: any | any[]) => {
          let label = ''
          if (multiple) {
            const aux: any = []
            selected.forEach((e: any) => {
              const option = options.find((o: any) => o.id === e)
              if (option) {
                aux.push(option.title)
              }
            })

            label = aux.join(', ')
          }
          return (
            <p className="font-montserrat text-[13px] font-normal leading-16 tracking-wider text-pitch-black overflow-hidden">
              {multiple ? label : options.find((e) => e.id === selected).title}
            </p>
          )
        }
      }}
      {...props}
    >
      {options.map((elem: Options) => (
        <MenuItem
          key={elem.id as number}
          value={elem.id}
          style={getStyles(
            elem.title,
            multiple ? (value as string[]) : ([value] as string[]),
            theme
          )}
        >
          <Checkbox
            checked={
              multiple
                ? typeof value === 'object'
                  ? value.some((e) => e === elem.id)
                  : false
                : value === elem.id
            }
          />
          <span className="text-pitch-black">{elem.title}</span>
        </MenuItem>
      ))}
    </TextField>
  )
}
