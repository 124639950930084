import React, { useState, useEffect } from 'react'
import { Layout } from '../../components/Layout'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'
import UserAvatar from '../../components/UserAvatar'
import EmptyAvatar from '../../assets/avatar_circular.png'
import { Typography, Button } from '@mui/material'
import format from 'date-fns/format'
import { CustomDatePicker } from '../../components/CustomDatePicker'
import { getAppointments } from '../../services/appointments'
import { Appointment } from '../../utils/constants'
import NothingToShow from '../../components/nothingToShow'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import CustomModal from '../../components/customModal'
import ModalExclamation from '../../assets/modal-exclamation.png'
import { addWeeks, addDays } from 'date-fns'
import { AppointmentSlotType } from '../../utils/constants'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useNavigate } from 'react-router-dom'

const AppoinmentsScreen = () => {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(addWeeks(new Date(), 1))
  const [appointmentsData, setAppointmentsData] = useState<Appointment[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false) //To show that there is an ongoing appoinment
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { videocall } = AppointmentSlotType

  useEffect(() => {
    const getAppointmentsData = async () => {
      setIsLoading(true)
      const response = await getAppointments(startDate, endDate)

      if (response.items) {
        setAppointmentsData(response.items as Appointment[])
      }
      setIsLoading(false)
    }

    if (startDate < endDate) {
      getAppointmentsData()
    } else {
      setEndDate(addDays(startDate, 1))
    }
  }, [startDate, endDate])

  const isCurrentAppointment = (date: Date) => {
    const appointmentDuration = 30
    const now = new Date() // current date and time
    const start = new Date(date) // start date and time of the appointment
    const currentStart = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      Math.floor(now.getMinutes() / 30) * 30
    ) // start date and time of the current 30-minute range
    const currentEnd = new Date(
      currentStart.getTime() + appointmentDuration * 60 * 1000
    ) // end date and time of the current 30-minute range

    // check if the appointment starts within the current 30-minute range
    return start >= currentStart && start < currentEnd
  }

  const columns: GridColDef[] = [
    {
      field: 'patient',
      headerName: 'Cliente',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Cliente
          </Typography>
        )
      },
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center pl-4">
            <UserAvatar imageUrl={EmptyAvatar} size={44} altText={'IMG'} />
            <Typography className="pl-8">
              {cellValues.row.patient.firstName}{' '}
              {cellValues.row.patient.lastName}
            </Typography>
          </div>
        )
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: 'Fecha',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return <Typography fontWeight="bold">Fecha</Typography>
      },
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <Typography>
              {format(new Date(cellValues.row.startDate), 'dd/MM/yyyy - HH:mm')}{' '}
              hs
            </Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'specialty',
      headerName: 'Especialidad',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return <Typography fontWeight="bold">Especialidad</Typography>
      },
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <Typography>{cellValues.row.specialty.title}</Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'appointmentType',
      headerName: 'Tipo de consulta',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return <Typography fontWeight="bold">Tipo de consulta</Typography>
      },
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <Typography className="capitalize">
              {cellValues.row.appointmentType === videocall
                ? 'Videollamada'
                : 'Presencial'}
            </Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'hypertext',
      headerName: '',
      editable: false,
      renderHeader: (params: GridColumnHeaderParams): any => {
        return <div className="pl-4"></div>
      },
      renderCell: (cellValues): any => {
        const { patient, id, startDate } = cellValues.row
        return (
          <div className="flex w-full justify-center">
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                navigate(`/video-room`, {
                  replace: true,
                  state: {
                    id,
                    patient: `${patient.firstName} ${patient.lastName}`
                  }
                })
              }
              disabled={!isCurrentAppointment(startDate)}
            >
              Ir al turno
            </Button>
          </div>
        )
      },
      flex: 1,
      sortable: false
    }
  ]

  return (
    <Layout>
      <div className="flex flex-col w-full h-full pb-8">
        <div className="flex w-full items-center justify-between">
          <div className="flex w-1/5 justify-start pl-2 pr-2">
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight="bold"
            >
              Turnos
            </Typography>
          </div>
          <div className="flex w-4/5 justify-end pr-14">
            <div className="flex mr-4">
              <CustomDatePicker
                label="Desde"
                value={startDate}
                onChange={setStartDate}
                className="w-42"
                disablePast
              />
            </div>
            <CustomDatePicker
              label="Hasta"
              value={endDate}
              onChange={setEndDate}
              className="w-42"
              disablePast
            />
          </div>
        </div>
        <div className="bg-white mx-14">
          {appointmentsData.length > 0 ? (
            <div className="flex mr-4 h-[495px]">
              <CustomDataGrid
                rows={appointmentsData}
                rowHeight={64}
                columns={columns}
              />
            </div>
          ) : (
            !isLoading && (
              <NothingToShow
                nothingImg={nothingToShowImg}
                altText={'sin turnos para mostrar'}
                nothingText={
                  'No existen turnos reservados dentro de este rango de fechas...'
                }
              />
            )
          )}
        </div>
        <CustomModal
          openModal={openModal}
          handleClose={() => {
            setOpenModal(false)
          }}
          imgSrc={ModalExclamation}
          title="Turno en curso"
          description="Recordá que tenés un turno en curso. Ingresá a la sala cuanto antes para dar comienzo a la consulta"
          onSubmit={() => undefined}
          submitLabel="Ir al turno"
          showCancelButton={false}
          showCrossCancelButton={true}
          child={null}
          isSubmitDisable={null}
        />
      </div>
    </Layout>
  )
}

export default AppoinmentsScreen
